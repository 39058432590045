import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'

Vue.use(Router)
//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

/* Layout */
import Layout from '../views/layout/Layout'
import Layout2 from '../views/layout/Layout2'
import Layout3 from '../views/layout/Layout3'
import VueCryptojs from 'vue-cryptojs'

Vue.use(VueCryptojs)
// import signList from '../views/home/signList'

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
 **/
export const router = new Router({
  routes: [{
    path: '/',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/share',
    component: () => import('@/views/shareCode/index'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  //系统管理员页面
  {
    path: '/home',
    component: Layout3,
    // redirect: '/home',
    meta: {
      title: '首页',
      icon: 'el-icon-s-custom',
      roles: ['admin']
    },
    children: [{
      path: 'home',
      name: 'home',
      component: () => import('@/views/home/index'),
      meta: {
        title: '首页',
        icon: 'el-icon-s-custom',
        roles: ['admin']
      }
    },
    {
      path: 'memberList',
      name: 'memberList',
      component: Layout2,
      // component: () => import('@/views/home/signList'),
      meta: {
        title: '签到活动',
        roles: ['admin']
      },
      redirect: '/home/memberList/memberList',
      hidden: true,
      children: [{
        path: 'memberList',
        name: 'memberList',
        component: () => import('@/views/home/memberlist/index'),
        meta: {
          title: '签到活动',
          roles: ['admin']
        },
      },
      {
        path: 'editmemberList',
        name: 'editmemberList',
        component: () => import('@/views/home/memberlist/editlist'),
        meta: {
          title: '签到活动',
          roles: ['admin']
        },
      },
      ]
    },
    {
      path: 'signList',
      name: 'signList',
      component: Layout2,
      // component: () => import('@/views/home/signList'),
      meta: {
        title: '签到活动',
        roles: ['admin']
      },
      redirect: '/home/signList/signList',
      hidden: true,
      children: [{
        path: 'signList',
        name: 'signList',
        component: () => import('@/views/home/signList'),
        meta: {
          title: '签到活动',
          roles: ['admin']
        },
      },
      {
        path: 'signlinkList',
        name: 'signlinkList',
        component: Layout2,
        meta: {
          title: '签到环节',
          roles: ['admin']
        },
        hidden: true,
        redirect: '/home/signList/signlinkList/signlinkList',
        children: [{
          path: 'signlinkList',
          name: 'signlinkList',
          component: () => import('@/views/home/signlinkList'),
          meta: {
            title: '签到环节',
            roles: ['admin']
          },
          hidden: true
        },
        {
          path: 'signResult',
          name: 'signResult',
          component: () => import('@/views/home/signResult'),
          meta: {
            title: '签到结果',
            roles: ['admin']
          },
          hidden: true
        }
        ]
      }
      ]
    }
    ]
  },
  //系统管理员审批
  {
    path: '/shenpi',
    component: Layout3,
    redirect: '/shenpi/index',
    name: 'pms',
    meta: {
      title: '商品',
      icon: 'product',
      roles: ['admin']
    },
    children: [{
      path: 'index',
      name: 'index',
      component: () => import('@/views/home/schooladminapply'),
      meta: {
        title: '审批申请',
        icon: 'el-icon-s-check',
        roles: ['admin']
      }
    }]

  },
  //我的课程
  {
    path: '/mycourse',
    name: 'mycourse',
    meta: {
      title: '我的课程',
      icon: 'el-icon-notebook-1',
      // roles: ['user', 'xueyuan', 'school', 'major'],
      roles: ['user']
    },
    component: Layout3,
    children: [
      //签到活动
      {
        path: 'courseList',
        name: 'courseList',
        component: () => import('@/views/user/mysigncourse'),
        meta: {
          title: '签到活动',
          icon: 'el-icon-folder',
          // roles: ['xueyuan', 'admin', 'school'],
          roles: ['user']
        },
      },
      {
        path: 'signList',
        name: 'signList',
        component: () => import('@/views/user/mysignList'),
        meta: {
          title: '签到活动',
          // roles: ['school', 'xueyuan', 'major'],
          roles: ['user']
        },
        hidden: true,
      },
      {
        path: 'signlinkList',
        name: 'signlinkList',
        component: Layout2,
        meta: {
          title: '签到环节',
          // roles: ['school', 'xueyuan', 'major']
          roles: ['user']
        },
        redirect: '/mycourse/signlinkList/signlinkList',
        hidden: true,
        children: [{
          path: 'signlinkList',
          name: 'signlinkList',
          component: () => import('@/views/user/mysignlinkList'),
          meta: {
            title: '签到环节',
            // roles: ['school', 'xueyuan', 'major']
            roles: ['user']
          },
        },
        {
          path: 'signCode',
          name: 'signCode',
          component: () => import('@/views/user/mysignCode'),
          meta: {
            title: '签到二维码',
            // roles: ['user', 'xueyuan', 'school', 'major']
            roles: ['user']
          },
          hidden: true
        },
        {
          path: 'signResult',
          name: 'signResult',
          component: () => import('@/views/user/mysignResult'),
          meta: {
            title: '签到结果',
            // roles: ['user', 'xueyuan', 'school', 'major']
            roles: ['user']
          },
          hidden: true
        }
        ]
      },
      //课程管理
      {
        path: 'manage',
        name: 'manage',
        component: () => import('../views/user/coursemanage/index'),
        meta: {
          title: '课程管理',
          icon: 'el-icon-document-checked',
          // roles: ['user', 'xueyuan', 'school', 'major']
          roles: ['user']
        }
      }
    ]
  },

  //各类用户的签到名单
  {
    path: '/memberlist',
    component: Layout3,
    meta: {
      title: '签到名单',
      icon: 'home',
      // roles: ['user', 'xueyuan', 'major', 'school']
      roles: ['user']
    },
    redirect: '/memberlist/memberlist1',
    children: [{
      path: 'memberlist1',
      name: 'memberlist1',
      component: () => import('@/views/user/memberlist/index'),
      meta: {
        title: '签到名单',
        icon: 'el-icon-s-order',
        // roles: ['user', 'xueyuan', 'major', 'school']
        roles: ['user']
      }
    },
    {
      path: 'memberlist2',
      name: 'memberlist2',
      component: () => import('@/views/user/memberlist/editlist'),
      meta: {
        title: '修改名单',
        icon: 'home',
        // roles: ['user', 'xueyuan', 'major', 'school']
        roles: ['user']
      },
      hidden: true
    },
    ]
  },
  //组织管理员
  {
    path: '/zuzhi',
    name: 'zuzhi',
    component: Layout3,
    meta: {
      title: '我的组织',
      icon: 'el-icon-menu',
      // roles: ['major', 'school', 'xueyuan'],
      roles: ['user']
    },
    children: [{
      path: 'home',
      name: 'home',
      component: () => import('@/views/user/organization/myOrganization'),
      meta: {
        title: '组织管理',
        icon: 'el-icon-s-custom',
        // roles: ['school', 'xueyuan', 'major'],
        roles: ['user']
      }
    },
    {
      path: 'myOrganizationList',
      name: 'myOrganizationList',
      component: Layout2,
      meta: {
        title: '组织管理详情',
        // roles: ['school', 'xueyuan', 'major']
        roles: ['user']
      },
      redirect: '/zuzhi/myOrganizationList/myOrganizationList',
      hidden: true,
      children: [{
        path: 'myOrganizationList',
        name: 'myOrganizationList',
        component: () => import('@/views/user/organization/myOrganizationList'),
        meta: {
          title: '组织管理详情',
          // roles: ['school', 'xueyuan', 'major']
          roles: ['user']
        },
      },]
    },
    //考勤情况 
    {
      path: 'checkAttendance',
      name: 'checkAttendance',
      component: () => import('@/views/user/checkAttendance/index'),
      meta: {
        title: '下级列表',
        icon: 'el-icon-time',
        // roles: ['xueyuan', 'admin', 'school']
        roles: ['user']
      },
    },
    {
      path: 'organizationChart',
      name: 'organizationChart',
      component: () => import('@/views/user/checkAttendance/organizationChart'),
      meta: {
        title: '组织考勤',
        // roles: ['xueyuan', 'admin', 'school']
        roles: ['user']
      },
      hidden: true,
    },
    {
      path: 'organizationList',
      name: 'organizationList',
      component: Layout2,
      meta: {
        title: '我的组织详情',
        // roles: ['school', 'xueyuan', 'major']
        roles: ['user']
      },
      redirect: '/zuzhi/organizationList/myOrganizationList1',
      hidden: true,
      children: [{
        path: 'myOrganizationList1',
        name: 'myOrganizationList1',
        component: () => import('@/views/user/checkAttendance/organizationList'),
        meta: {
          title: '我的组织详情',
          // roles: ['school', 'xueyuan', 'major']
          roles: ['user']
        },
      },]
    },
    {
      path: 'historyAttendance',
      name: 'historyAttendance',
      component: Layout2,
      // component: () => import('@/views/home/signList'),
      meta: {
        title: '组织考勤统计',
        // roles: ['school', 'xueyuan', 'major']
        roles: ['user']
      },
      redirect: '/zuzhi/historyAttendance/historyAttendance1',
      hidden: true,
      children: [{
        path: 'historyAttendance1',
        name: 'historyAttendance1',
        component: () => import('@/views/user/checkAttendance/historyAttendance/historyAttendanceIndex'),
        meta: {
          title: '组织考勤统计',
          // roles: ['school', 'xueyuan', 'major']
          roles: ['user']
        },
      },]
    },
    //课程考勤统计
    {
      path: 'courseAttendance',
      name: 'courseAttendance',
      component: Layout2,
      // component: () => import('@/views/home/signList'),
      meta: {
        title: '课程考勤统计',
        // roles: ['school', 'xueyuan', 'major']
        roles: ['user']
      },
      redirect: '/zuzhi/courseAttendance/courseAttendance1',
      hidden: true,
      children: [{
        path: 'courseAttendance1',
        name: 'courseAttendance1',
        component: () => import('@/views/user/checkAttendance/historyAttendance/courseAttendance'),
        meta: {
          title: '课程考勤统计',
          // roles: ['school', 'xueyuan', 'major']
          roles: ['user']
        },
      },]
    },

    {
      path: 'nowSign',
      name: 'nowSign',
      hidden: true,
      component: () => import('@/views/user/checkAttendance/nowSign/courseSign.vue'),
      meta: {
        title: '课程正在签到',
        icon: 'el-icon-time',
        // roles: ['xueyuan', 'admin', 'school']
        roles: ['user']
      },
    },

    {
      path: 'courseHistory',
      component: Layout2,
      meta: {
        title: '课程历史签到活动',
        icon: 'el-icon-time',
        // roles: ['xueyuan', 'admin', 'school']
        roles: ['user']
      },
      hidden: true,
      redirect: '/zuzhi/courseHistory/courseHistory1',
      children: [{
        path: 'courseHistory1',
        name: 'courseHistory1',
        component: () => import('../views/user/checkAttendance/historyAttendance/courseList.vue'),
        hidden: true,
        meta: {
          title: '课程历史签到活动',
          icon: 'el-icon-time',
          // roles: ['xueyuan', 'admin', 'school']
          roles: ['user']
        },
      }]
    },
    {
      path: 'courseHistoryLink',
      component: Layout2,
      meta: {
        title: '课程历史签到环节',
        icon: 'el-icon-time',
        // roles: ['xueyuan', 'admin', 'school']
        roles: ['user']
      },
      hidden: true,
      redirect: '/zuzhi/courseHistoryLink/courseHistoryLink1',
      children: [{
        path: 'courseHistoryLink1',
        name: 'courseHistoryLink1',
        component: () => import('../views/user/checkAttendance/historyAttendance/courseResult.vue'),
        hidden: true,
        meta: {
          title: '课程历史签到环节',
          icon: 'el-icon-time',
          // roles: ['xueyuan', 'admin', 'school']
          roles: ['user']
        },
      },
      {
        path: 'courseHistoryResult',
        name: 'courseHistoryResult',
        component: () => import('../views/user/checkAttendance/historyAttendance/coursResultEcharts.vue'),
        hidden: true,
        meta: {
          title: '课程历史签到结果',
          icon: 'el-icon-time',
          // roles: ['xueyuan', 'admin', 'school']
          roles: ['user']
        },
      }
      ]
    },
      // {
      //   path: 'organzationHistory',
      //   component: Layout2,
      //   meta: {
      //     title: '课程历史签到活动',
      //     icon: 'el-icon-time',
      //     // roles: ['xueyuan', 'admin', 'school']
      //     roles: ['user']
      //   },
      //   hidden: true,
      //   redirect: '/checkAttendance/organzationHistory/organzationHistory',
      //   children: [{
      //     path: 'organzationHistory',
      //     name: 'organzationHistory',
      //     component: () => import('../views/user/checkAttendance/historyAttendance/organzationHistory.vue'),
      //     hidden: true,
      //     meta: {
      //       title: '课程历史签到活动',
      //       icon: 'el-icon-time',
      //       // roles: ['xueyuan', 'admin', 'school']
      //       roles: ['user']
      //     },
      //   }]
      // },
      //  //考勤情况复制
      //  {
      //   path: '/checkAttendance',
      //   name: 'checkAttendance',
      //   component: Layout2,
      //   meta: {
      //     title: '考核情况',
      //     icon: 'el-icon-time',
      //     // roles: ['xueyuan', 'admin', 'school']
      //     roles: ['user']
      //   },
      //   redirect: '/checkAttendance/condition',
      //   children: [{
      //       path: 'condition',
      //       name: 'condition',
      //       component: () => import('@/views/user/checkAttendance/index'),
      //       meta: {
      //         title: '考核情况',
      //         icon: 'el-icon-time',
      //         // roles: ['xueyuan', 'admin', 'school']
      //         roles: ['user']
      //       },
      //     },
      //     {
      //       path: 'organizationList',
      //       name: 'organizationList',
      //       component: Layout2,
      //       meta: {
      //         title: '我的组织详情',
      //         // roles: ['school', 'xueyuan', 'major']
      //         roles: ['user']
      //       },
      //       redirect: '/checkAttendance/organizationList/myOrganizationList',
      //       hidden: true,
      //       children: [{
      //         path: 'myOrganizationList',
      //         name: 'myOrganizationList',
      //         component: () => import('@/views/user/checkAttendance/organizationList'),
      //         meta: {
      //           title: '我的组织详情',
      //           // roles: ['school', 'xueyuan', 'major']
      //           roles: ['user']
      //         },
      //       }, ]
      //     },
      //     {
      //       path: 'historyAttendance',
      //       name: 'historyAttendance',
      //       component: Layout2,
      //       // component: () => import('@/views/home/signList'),
      //       meta: {
      //         title: '组织考勤统计',
      //         // roles: ['school', 'xueyuan', 'major']
      //         roles: ['user']
      //       },
      //       redirect: '/checkAttendance/historyAttendance/historyAttendance',
      //       hidden: true,
      //       children: [{
      //         path: 'historyAttendance',
      //         name: 'historyAttendance',
      //         component: () => import('@/views/user/checkAttendance/historyAttendance/historyAttendanceIndex'),
      //         meta: {
      //           title: '组织考勤统计',
      //           // roles: ['school', 'xueyuan', 'major']
      //           roles: ['user']
      //         },
      //       }, ]
      //     },
      //     //课程考勤统计
      //     {
      //       path: 'courseAttendance',
      //       name: 'courseAttendance',
      //       component: Layout2,
      //       // component: () => import('@/views/home/signList'),
      //       meta: {
      //         title: '课程考勤统计',
      //         // roles: ['school', 'xueyuan', 'major']
      //         roles: ['user']
      //       },
      //       redirect: '/checkAttendance/courseAttendance/courseAttendance',
      //       hidden: true,
      //       children: [{
      //         path: 'courseAttendance',
      //         name: 'courseAttendance',
      //         component: () => import('@/views/user/checkAttendance/historyAttendance/courseAttendance'),
      //         meta: {
      //           title: '课程考勤统计',
      //           // roles: ['school', 'xueyuan', 'major']
      //           roles: ['user']
      //         },
      //       }, ]
      //     },
      //     {
      //       path: 'nowSign',
      //       name: 'nowSign',
      //       hidden: true,
      //       component: () => import('@/views/user/checkAttendance/nowSign/courseSign.vue'),
      //       meta: {
      //         title: '课程正在签到',
      //         icon: 'el-icon-time',
      //         // roles: ['xueyuan', 'admin', 'school']
      //         roles: ['user']
      //       },
      //     },
      //     {
      //       path: 'courseHistory',
      //       component: Layout2,
      //       meta: {
      //         title: '课程历史签到活动',
      //         icon: 'el-icon-time',
      //         // roles: ['xueyuan', 'admin', 'school']
      //         roles: ['user']
      //       },
      //       hidden: true,
      //       redirect: '/checkAttendance/courseHistory/courseHistory',
      //       children: [{
      //         path: 'courseHistory',
      //         name: 'courseHistory',
      //         component: () => import('../views/user/checkAttendance/historyAttendance/courseList.vue'),
      //         hidden: true,
      //         meta: {
      //           title: '课程历史签到活动',
      //           icon: 'el-icon-time',
      //           // roles: ['xueyuan', 'admin', 'school']
      //           roles: ['user']
      //         },
      //       }]
      //     },
      //     {
      //       path: 'courseHistoryLink',
      //       component: Layout2,
      //       meta: {
      //         title: '课程历史签到环节',
      //         icon: 'el-icon-time',
      //         // roles: ['xueyuan', 'admin', 'school']
      //         roles: ['user']
      //       },
      //       hidden: true,
      //       redirect: '/checkAttendance/courseHistoryLink/courseHistoryLink',
      //       children: [{
      //           path: 'courseHistoryLink',
      //           name: 'courseHistoryLink',
      //           component: () => import('../views/user/checkAttendance/historyAttendance/courseResult.vue'),
      //           hidden: true,
      //           meta: {
      //             title: '课程历史签到环节',
      //             icon: 'el-icon-time',
      //             // roles: ['xueyuan', 'admin', 'school']
      //             roles: ['user']
      //           },
      //         },
      //         {
      //           path: 'courseHistoryResult',
      //           name: 'courseHistoryResult',
      //           component: () => import('../views/user/checkAttendance/historyAttendance/coursResultEcharts.vue'),
      //           hidden: true,
      //           meta: {
      //             title: '课程历史签到结果',
      //             icon: 'el-icon-time',
      //             // roles: ['xueyuan', 'admin', 'school']
      //             roles: ['user']
      //           },
      //         }
      //       ]
      //     },
      //   ]
      // }
    ]
  },
  //各类管理员审批申请
  {
    path: '/checkCommonApply2',
    name: 'checkCommonApply2',
    component: Layout3,
    meta: {
      title: '审核申请',
      icon: 'home',
      // roles: ['xueyuan', 'major', 'school']
      roles: ['user']
    },
    redirect: '/zuzhi/checkCommonApply2/checkCommonApply21',
    children: [{
      path: 'checkCommonApply21',
      name: 'checkCommonApply21',
      component: () => import('@/views/user/zuzhiAdminApply/index'),
      meta: {
        title: '审核申请',
        icon: 'el-icon-s-check',
        // roles: ['xueyuan', 'admin', 'school']
        roles: ['user']
      }
    },
    {
      path: 'checkCommonApplyList',
      name: 'checkCommonApplyList',
      component: () => import('@/views/user/zuzhiAdminApply/commonApply'),
      meta: {
        title: '申请列表',
        icon: 'el-icon-s-check',
        // roles: ['xueyuan', 'admin', 'school']
        roles: ['user']
      },
      hidden: true
    }
    ]
  },
  //组织创建
  // {
  //   path: '/organization',
  //   name: 'organization',
  //   component: Layout3,
  //   meta: {
  //     title: '组织创建',
  //     icon: 'home',
  //     roles: ['user', 'xueyuan', 'school', 'major']
  //   },
  //   redirect: '/organization/created',
  //   children: [{
  //     path: 'created',
  //     name: 'created',
  //     component: () => import('@/views/user/createorganization/index'),
  //     meta: {
  //       title: '组织创建',
  //       icon: 'el-icon-s-promotion',
  //       roles: ['user', 'xueyuan', 'school', 'major']
  //     }
  //   },]
  // },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
  ]
});
export default router



router.beforeEach((to, from, next) => {
  var roleid = sessionStorage.getItem("role")
  let role = 'user'
  if (roleid == -1)
    role = 'admin'
  // else if (roleid == 1)
  //   role = 'school'
  // else if (roleid == 2)
  //   role = 'xueyuan'
  // else if (roleid == 3)
  //   role = 'major'
  const tokenStr = window.sessionStorage.getItem('Authorization')
  const loginValue = window.sessionStorage.getItem('loginvalue')
  if (to.path === '/') {
    console.log("前往登录")
    return next()
  } else if (to.path === '/share')
    return next()
  else {
    if (!tokenStr) {
      return next('/')
    } else {
      console.log("当前的role", to)
      console.log("当前的role", role)
      if (to.meta.roles.indexOf(role) > -1) {
        next();
      } else {
        console.log("权限不够！！！")
        next({
          path: "/404"
        })
      }
    }
  }
})
