<template>
  <div class="menu-wrapper">
    <!-- 不隐藏，有子路由 -->
    <template v-for="item in newroutes" v-if="!item.hidden && item.children">
      <!-- 一级菜单 -->
      <router-link
        v-if="
          hasOneShowingChildren(item.children) &&
            !item.children[0].children &&
            !item.alwaysShow
        "
        :to="item.path + '/' + item.children[0].path"
        :key="item.children[0].name"
      >
        <el-menu-item
          :index="item.path + '/' + item.children[0].path"
          :class="{ 'submenu-title-noDropdown': !isNest }"
        >
          <!-- <i class="el-icon-s-home"></i> -->
          <!-- <svg-icon
            v-if="item.children[0].meta && item.children[0].meta.icon"
            :icon-class="item.children[0].meta.icon"
          ></svg-icon> -->
          <i
            v-if="item.children[0].meta && item.children[0].meta.icon"
            :class="item.children[0].meta.icon"
          ></i>
          <span
            v-if="item.children[0].meta && item.children[0].meta.title"
            slot="title"
            >{{ item.children[0].meta.title }}</span
          >
        </el-menu-item>
      </router-link>

      <el-submenu v-else :index="item.name || item.path" :key="item.name">
        <template slot="title">
          <!-- <svg-icon
            v-if="item.meta && item.meta.icon"
            :icon-class="item.meta.icon"
          ></svg-icon> -->
          <i v-if="item.meta && item.meta.icon" :class="item.meta.icon"></i>
          <span v-if="item.meta && item.meta.title" slot="title">{{
            item.meta.title
          }}</span>
        </template>

        <template v-for="child in item.children" v-if="!child.hidden">
          <sidebar-item
            :is-nest="true"
            class="nest-menu"
            v-if="child.children && child.children.length > 0"
            :routes="[child]"
            :key="child.path"
          ></sidebar-item>

          <!-- 二级菜单 -->
          <!-- <router-link
            v-else-if="
              hasOneShowingChildren(child.children) &&
              !child.children[0].children &&
              !child.alwaysShow
            "
            :to="item.path + '/' + child.children[0].path"
            :key="child.children[0].name"
          >
            <el-menu-item :index="item.path + '/' + child.children[0].path">
              <svg-icon
                v-if="child.children[0].meta && child.children[0].icon"
                :icon-class="child.children[0].meta.icon"
              ></svg-icon>
              <span
                v-if="child.children[0].meta && child.children[0].meta.title"
                slot="title"
                >{{ child.children[0].meta.title }}</span
              >
            </el-menu-item>
          </router-link> -->
          <router-link
            v-else
            :to="item.path + '/' + child.path"
            :key="child.name"
          >
            <el-menu-item :index="item.path + '/' + child.path">
              <!-- <svg-icon
                v-if="child.meta && child.meta.icon"
                :icon-class="child.meta.icon"
              ></svg-icon> -->
              <i
                v-if="child.meta && child.meta.icon"
                :class="child.meta.icon"
              ></i>
              <span v-if="child.meta && child.meta.title" slot="title">{{
                child.meta.title
              }}</span>
            </el-menu-item>
          </router-link>

          <!-- <el-submenu v-else :index="child.name || child.path" :key="child.name">
            <template slot="title">
              <svg-icon
                v-if="child.meta && child.meta.icon"
                :icon-class="child.meta.icon"
              ></svg-icon>
              <span v-if="child.meta && child.meta.title" slot="title">{{
                child.meta.title
              }}</span>
            </template>

            <template v-for="kid in child.children" v-if="!kid.hidden">
              <sidebar-item
                :is-nest="true"
                class="nest-menu"
                v-if="kid.children && kid.children.length > 0"
                :routes="[kid]"
                :key="kid.path"
              ></sidebar-item>

              <router-link
                v-else
                :to="child.path + '/' + kid.path"
                :key="kid.name"
              >
                <el-menu-item :index="child.path + '/' + kid.path">
                  <svg-icon
                    v-if="kid.meta && kid.meta.icon"
                    :icon-class="kid.meta.icon"
                  ></svg-icon>
                  <span v-if="kid.meta && kid.meta.title" slot="title">{{
                    kid.meta.title
                  }}</span>
                </el-menu-item>
              </router-link>
            </template>
          </el-submenu> -->
        </template>
      </el-submenu>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SidebarItem',
  props: {
    routes: {
      type: Array
    },
    isNest: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // routes2: [],
      newroutes: []
    }
  },
  created() {
    // this.routes2=this.$router.options.routes
    // console.log("这是我的路由",this.$router.options)
    var roleid = sessionStorage.getItem('role')
    let role = 'user'
    if (roleid == -1) role = 'admin'
    // else if(roleid==1)
    //   role='school'
    // else if(roleid==2)
    //   role='xueyuan'
    // else if(roleid==3)
    //   role='major'
    console.log('现在的角色', role)
    // if(this.$router.options.routes[5].meta.roles[0]==role){
    //   console.log("包含")
    // }else
    //   console.log("bu包含")

    // this.$router.options.routes.forEach(function(item) {
    //     if(item.meta)
    //       if(item.meta.roles.indexOf(role)>-1){
    //         console.log("路由名称:",item.meta.title)
    //         console.log("路由角色:",item.meta.roles)
    //       }
    // });
    var newroutes = this.routes.filter(item => {
      if (item.meta) return item.meta.roles.indexOf(role) > -1
    })
    this.newroutes = newroutes
    console.log('newrouteschushihua', newroutes)

    // console.log("所有路由",this.$router.options.routes)

    // for(var i in this.$router.options.routes){
    //   console.log(i.meta.roles)
    // }

    // if(window.sessionStorage.loginvalue == '1') {
    //   var r = this.routes.filter((item) => {
    //     return item.path!='/pms'
    //   })
    //   this.routes2 = r.filter((item) => {
    //     return item.path!='/home'
    //   })
    // } else {
    //   var r = this.routes.filter((item) => {
    //     return item.path !='/signList'
    //   })
    //   this.routes2 = r
    // }
  },
  methods: {
    hasOneShowingChildren(children) {
      const showingChildren = children.filter(item => {
        return !item.hidden
      })
      if (showingChildren.length === 1) {
        return true
      }
      return false
    }
  }
}
</script>
