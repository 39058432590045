<template>
  <div class="app-container-result">
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/mycourse/courseList?ccid=' + ccid }">课程签到</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/mycourse/signList?actId=' + actId }">签到活动</el-breadcrumb-item>
      <el-breadcrumb-item :to="{
        path: '/mycourse/signlinkList?actId=' + actId
      }">签到环节</el-breadcrumb-item>
      <el-breadcrumb-item>签到结果</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- <el-breadcrumb separator="/" class="breadcrumb" v-if="flag == 1">
      <el-breadcrumb-item :to="{ path: '/signList/courseList' }"
        >课程签到</el-breadcrumb-item
      >
      <el-breadcrumb-item
        :to="{
          path: flag == 1 ? '/signList/signList' : '/signList/signList'
        }"
        >签到活动</el-breadcrumb-item
      >
      <el-breadcrumb-item
        :to="{
          path:
            flag == 1
              ? '/signList/signlinkList?actId=' + actId
              : '/signList/signList/signlinkList/signlinkList?actId=' + actId
        }"
        >签到环节</el-breadcrumb-item
      >
      <el-breadcrumb-item>签到结果</el-breadcrumb-item>
    </el-breadcrumb>
    <el-breadcrumb separator="/" class="breadcrumb" v-else>
      <el-breadcrumb-item :to="{ path: '/home/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/home/signList/signList?tid=' + tid }"
        >签到活动</el-breadcrumb-item
      >
      <el-breadcrumb-item
        :to="{
          path: '/home/signList/signlinkList/signlinkList?actId=' + actId
        }"
        >签到环节</el-breadcrumb-item
      >
      <el-breadcrumb-item>签到结果</el-breadcrumb-item>
    </el-breadcrumb> -->
    <div class="result-container">
      <el-card class="operate-container" style="height: 100%" shadow="never">
        <el-row :gutter="20" style="margin-bottom: 20px">
          <el-col :span="18">
            <el-row :gutter="20">
              <el-col :span="8">
                <div class="total-frame">
                  <img :src="img_home_order" class="total-icon" />
                  <div class="total-title">课程总人数</div>
                  <div class="total-value">{{ signnum + unsignnum }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="total-frame">
                  <img :src="img_home_today_amount" class="total-icon" />
                  <div class="total-title">已签到人数</div>
                  <div class="total-value">{{ signnum }}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="total-frame">
                  <img :src="img_home_yesterday_amount" class="total-icon" />
                  <div class="total-title">未签到人数</div>
                  <div class="total-value">{{ unsignnum }}</div>
                </div>
              </el-col>
            </el-row>
          </el-col>

          <el-col :span="5" style="text-align: center">
            <!--            <el-button-->
            <!--              :loading="downloadLoading"-->
            <!--              style="margin-top: 20px; margin-bottom: 20px; height: 45px"-->
            <!--              type="primary"-->
            <!--              icon="el-icon-document"-->
            <!--              @click="clickDialog"-->
            <!--            >-->
            <!--              选择名单-->
            <!--            </el-button>-->
            <el-button :loading="downloadLoading" style="margin-top: 20px; margin-bottom: 20px; height: 45px"
              type="primary" icon="el-icon-document" @click="exportExcel">
              导出数据
            </el-button>
          </el-col>
        </el-row>
        <el-dialog title="选择名单" :visible.sync="dialogTableVisible" width="800px">
          <a @click="downloadxls()" style="color: #419eff; border-bottom: 0.5px dotted #419eff">下载模板</a>
          <div style="text-align: center">
            <el-upload class="upload-demo" drag action="https://www.xqzjgsu.top:8001/list/excel"
              :on-change="handleChange" :on-success="handleSuccess" :headers="headers" :data="listDataforexcel"
              name="list" ref="upload" accept=".xls,.xlsx">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将xls/xlsx文件拖到此处，或<em>点击上传</em>
              </div>
            </el-upload>
          </div>
          <div>
            <el-table :data="listHistory" height="400" style="margin-top: 0 !important">
              <el-table-column prop="name" label="签到名单"> </el-table-column>
              <el-table-column label="操作" width="180">
                <template slot-scope="scope">
                  <el-button size="mini" @click="handleCompare(scope.$index, scope.row)">对比</el-button>
                  <!-- <el-button
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)"
                    >删除</el-button
                  > -->
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-dialog>
        <el-row :gutter="20">
          <el-col :span="10">
            <el-tabs type="border-card" style="height: 500px; overflow-y: auto; overflow-x: hidden">
              <el-tab-pane label="已签到" style="height: 90%; overflow-y: auto; overflow-x: hidden">
                <div v-if="signlist.length == 0" style="text-align: center; margin-top: 15px">
                  这里空空如也~
                </div>
                <div v-for="item in signlist" :key="item.name" class="signlistclass" v-else style="align-items: center">
                  <div>
                    <el-avatar>{{ item.name.slice(-2) }}</el-avatar>
                    <span class="imgrightspan">({{ item.studentId }})</span>
                  </div>
                  <div style="position: absolute; right: 215px;">
                    <span v-if="item.isProxySignIn" class="state-tag">
                      {{ item.state }}
                    </span>
                    <!-- <el-dropdown
                      v-else
                      size="mini"
                      split-button
                      type="primary"
                      :class="getDropdownClass(item.state)"
                      @command="handleCommand"
                    >
                      {{ item.state }}
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          :command="composeValue('迟到', item.uid)"
                          >迟到</el-dropdown-item
                        >
                        <el-dropdown-item
                          :command="composeValue('请假', item.uid)"
                          >请假</el-dropdown-item
                        >
                        <el-dropdown-item
                          :command="composeValue('旷课', item.uid)"
                          >旷课</el-dropdown-item
                        >
                        <el-dropdown-item
                          :command="composeValue('其他', item.uid)"
                          >其他</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown> -->
                  </div>
                  <div>

                    <span v-if="item.state !== '签到'" :class="['status-tag', getStatusClass(item.state)]">{{
                      getStatusText(item.state) }}</span>
                    <span class="timeclass">{{ item.signintime }}</span>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="未签到" style="height: 90%; overflow-y: auto; overflow-x: hidden">
                <div v-if="unsignlist.length == 0" style="text-align: center; margin-top: 15px">
                  这里空空如也~
                </div>
                <div v-for="item in unsignlist" :key="item.name" class="signlistclass" v-else>
                  <div>
                    <el-avatar>{{ item.name.slice(-2) }}</el-avatar>
                    <span class="imgrightspan">({{ item.studentId || '未知' }})</span>
                    <div style="display:inline-block;position:absolute;right:22px;">
                      <el-dropdown size="mini" split-button type="primary" class="drop-replace"
                        @command="handleCommand2">
                        代签到
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item :command="composeValue2('未认证', item.name)">未认证</el-dropdown-item>
                          <el-dropdown-item :command="composeValue2('迟到', item.name)">迟到</el-dropdown-item>
                          <el-dropdown-item :command="composeValue2('请假', item.name)">请假</el-dropdown-item>
                          <el-dropdown-item :command="composeValue2('其他', item.name)">其他</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-col>
          <el-col :span="14" style="height: 500px">
            <div class="statistics-layout" style="height: 500px">
              <div class="layout-title">签到统计</div>
              <div style="padding: 5px">
                <el-row :gutter="20">
                  <el-col :span="8">
                    <div style="text-align: center">
                      <div style="color: #909399; font-size: 14px">
                        课程总人数
                      </div>
                      <div style="
                          color: #606266;
                          font-size: 24px;
                          padding: 10px 0;
                          text-align: center;
                        ">
                        {{ signnum + unsignnum }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div style="text-align: center">
                      <div style="color: #909399; font-size: 14px">
                        已签到人数
                      </div>
                      <div style="
                          color: #606266;
                          font-size: 24px;
                          padding: 10px 0;
                          text-align: center;
                        ">
                        {{ signnum }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div style="text-align: center">
                      <div style="color: #909399; font-size: 14px">
                        未签到人数
                      </div>
                      <div style="
                          color: #606266;
                          font-size: 24px;
                          padding: 10px 0;
                          text-align: center;
                        ">
                        {{ unsignnum }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <el-row :gutter="20">
                <el-col :span="12">
                  <div class="chart-wrapper">
                    <bar-chart v-if="flag" :barData="histogramData" />
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="chart-wrapper">
                    <pie-chart :pieData="pieData" />
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import PieChart from '../home/components/PieChart'
import BarChart from '../home/components/BarChart'
import FilenameOption from '../home/components/FilenameOption'
import AutoWidthOption from '../home/components/AutoWidthOption'
import BookTypeOption from '../home/components/BookTypeOption'
import UploadExcelComponent from '@/components/UploadExcel/index.vue'
import XLSX from 'xlsx'
import img_home_order from '@/assets/images/home_order.png'
import img_home_today_amount from '@/assets/signfill.png'
import img_home_yesterday_amount from '@/assets/unsignfill.png'
const DATA_FROM_BACKEND = {
  columns: ['date', 'orderCount', 'orderAmount']
}
export default {
  props: {
    // beforeUpload: Function, // eslint-disable-line
    onSuccess: Function // eslint-disable-line
  },
  name: 'signResult',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    UploadExcelComponent,
    // eslint-disable-next-line vue/no-unused-components
    FilenameOption,
    // eslint-disable-next-line vue/no-unused-components
    AutoWidthOption,
    // eslint-disable-next-line vue/no-unused-components
    BookTypeOption,
    PieChart,
    BarChart
  },
  data() {
    this.histogramSettings = {
      yAxisType: 'normal',
      min: 0,
      digit: 0
    }
    this.pieSettings = {
      roseType: 'radius'
      // radius: [15, 95],
      // center: ['50%', '38%'],
    }
    return {
      flag: false,
      histogramData: {
        xdata: ['已签到', '未签到'],
        arrdata: [0, 0]
      },

      pieData: {
        xdata: ['其他', '未签到', '旷课', '请假', '迟到', '签到', '未认证'],
        arrdata: []
      },

      excelData: {
        header: null,
        results: null
      },

      activeName: 'first',

      tableData: [],
      tableHeader: [],

      signnum: 0,
      unsignnum: 0,
      totalnum: 0,

      signlist: [],

      unsignlist: [],
      listLoading: false,
      multipleSelection: [],
      downloadLoading: false,
      filename: '',
      bookType: 'xlsx',
      autoWidth: true,

      sexList: [
        { label: '女', value: '0' },
        { label: '男', value: '1' }
      ],

      loading: false,
      dataEmpty: false,
      img_home_order,
      img_home_today_amount,
      img_home_yesterday_amount,

      flagg: null,

      fileList: [],
      dialogTableVisible: false,
      listHistory: [],

      headers: {
        Authorization: 'Bearar ' + sessionStorage.getItem('Authorization')
      },

      listData: { uid: window.sessionStorage.getItem('uid') },
      cuid: '',
      hasAdd: false,
      myRes: [],
      listDataforexcel: {
        listName: '',
        uid: window.sessionStorage.getItem('uid')
      },
      ccid: ''
    }
  },
  async created() {
    // 1-普通 2-管理员
    // this.flag = sessionStorage.getItem('loginvalue')
    this.ccid = window.sessionStorage.getItem('ccid')
    this.lid = this.$route.query.lid
    this.tid = window.sessionStorage.getItem('tid')
    this.actId = window.sessionStorage.getItem('actId')
    this.title = window.sessionStorage.getItem('title')
    await this.getSignList()
    await this.getUnsignList()
    this.selectDistinctState()
  },
  methods: {
    //下载模板
    downloadxls() {
      window.location.href = process.env.BASE_URL + '名单模板.xls'
    },
    // 获取签到列表
    async getSignList() {
      const { data: res } = await this.$http.get('rec/resultTeacher', {
        params: {
          lid: this.lid
        }
      })

      if (res.code == 200) {
        this.signlist = res.data[2].map(item => ({
          ...item,
          studentId: item.student_id || '未知'
        }))
        this.signnum = res.data[2].length
        if (!this.hasAdd) {
          this.totalnum += this.signnum
          this.hasAdd = true
        }
      } else {
        this.signlist = []
        this.signnum = 0
      }
    },
    // 签到状态下拉菜单点击事件
    async handleCommand(command) {
      // await this.onClick()

      // this.$message('click on item ' + uid)
      const { data: res } = await this.$http.get('/rec/setSignInState', {
        params: {
          lid: this.lid,
          uid: command.uid,
          state: command.state
        }
      })
      // console.log("下拉菜单",res)
      if (res.code == 200) {
        await this.getSignList()
        this.selectDistinctState()
      }
    },
    // 代签到
    async handleCommand2(command) {
      const { data: res } = await this.$http.get('/rec/recByTea2', {
        params: {
          lid: this.lid,
          name: command.name,
          state: command.state
        }
      })
      if (res.code == 200) {
        const updatedStudent = {
          ...res.data,
          isProxySignIn: true
        }

        await this.getSignList()
        await this.getUnsignList()
        this.selectDistinctState()
      }
    },
    composeValue(item, row) {
      return {
        state: item,
        uid: row
      }
    },
    composeValue2(item, row) {
      return {
        state: item,
        name: row
      }
    },
    // 获取未签到列表
    async getUnsignList() {
      const { data: res } = await this.$http.get('rec/resultTeacher2', {
        params: {
          lid: this.lid
        }
      })
      // console.log('未签到', res)
      if (res.code == 200) {
        // console.log(res.data[2])
        if (res.data) {
          this.unsignlist = res.data
          console.log('未签到的', this.unsignlist)
          this.unsignnum = res.data.length
          // this.totalnum += this.unsignnum
        }
        if (!this.hasAdd) {
          this.totalnum += this.unsignnum
          this.hasAdd = true
        }
        this.histogramData.arrdata = [this.signnum, this.unsignnum]

        // 收到了数据，开始渲染，其实也可以不要flag，因为已经实现了监听数据变化
        this.flag = true
      } else {
        this.unsignlist = []
        this.unsignnum = 0
        if (!this.hasAdd) {
          this.totalnum += this.unsignnum
          this.hasAdd = true
        }
        this.histogramData.arrdata = [this.signnum, this.unsignnum]
        // 收到了数据���开始渲染，其实也可以不要flag，因为已经实现了监听数据变化
        this.flag = true
      }
    },

    // 获取饼图数据
    async selectDistinctState() {
      const { data: res } = await this.$http.get('rec/selectDistinctState', {
        params: {
          lid: this.lid
        }
      })
      if (res.code == 200) {
        if (res.data.count >= 0) {
          this.pieData.arrdata = res.data.list
          this.pieData.arrdata.push({ value: this.unsignnum, name: '未签到' })
        }
      }
    },

    // 导出文件
    exportExcel() {
      this.listLoading = true
      this.$http({
        url: 'rec/excel',
        method: 'post',
        responseType: 'blob', // 表明返回服务器返回的数据类型
        data: {
          lid: this.lid,
          noSignIn: this.unsignlist
        }
      }).then(res => {
        // 处理返回的文件流
        const content = res.data
        const blob = new Blob([content])
        const fileName = '签到结果.xlsx'
        if ('download' in document.createElement('a')) {
          // 非IE下载
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href) // 释放URL 对象
          document.body.removeChild(elink)
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName)
        }
      })
    },
    //
    // downloadStrategyResult(params) {
    //   return request({
    //     url: `rec/excel`,
    //     method: "post",
    //     data: params,
    //     baseURL: "/store",
    //     responseType: "blob"  //一定要添加这句哦～～
    //   });
    // },
    // // 导出
    // downloadExcel() {
    //   let params ={
    //     id:this.storeId
    //   }
    //   //调用接口
    //   storeAnalysis.downloadStrategyResult(params).then((res) => {
    //     this.downloadCallback(res, '巡检结果信息.xlsx');
    //   });
    // },
    // //生成下载文件
    // downloadCallback(res, fileName){
    //   const content = res.data;
    //   const blob = new Blob([content]);
    //   if ("download" in document.createElement("a")) {
    //     // 非IE下载
    //     const elink = document.createElement("a");
    //     elink.download = fileName;
    //     elink.style.display = "none";
    //     elink.href = URL.createObjectURL(blob);
    //     document.body.appendChild(elink);
    //     elink.click();
    //     URL.revokeObjectURL(elink.href); // 释放URL 对象
    //     document.body.removeChild(elink);
    //   } else {
    //     // IE10+下载
    //     navigator.msSaveBlob(blob, fileName);
    //   }
    // },

    formatterSex({ cellValue }) {
      const item = this.sexList.find(item => item.value === cellValue)
      return item ? item.label : ''
    },

    // 获取历史单
    async getHistoryList() {
      const { data: res } = await this.$http.get('/list/get2', {
        params: this.listData
      })
      this.listHistory = res.data.list
    },

    // 获取上传的文件
    async handleChange(file, fileList) {
      var filenamesplit = file.name.split('.')
      this.listDataforexcel.listName = filenamesplit[0]
      this.getHistoryList()
    },
    //上传成功
    handleSuccess(e) {
      this.getHistoryList()
      if (e.code == 200) {
        this.$message({
          type: 'success',
          message: e.message
        })
      } else {
        this.$message({
          type: 'error',
          message: e.message
        })
      }
    },

    clickDialog() {
      this.dialogTableVisible = true
      this.getHistoryList()
    },

    // 上传文件前

    // 选中名单
    async handleCompare(index, row) {
      const { data: res } = await this.$http.get('rec/contrast2', {
        params: {
          linkId: this.lid,
          listId: row.id
        }
      })
      if (res.code == 200) {
        this.$message({
          message: res.message,
          type: 'success',
          duration: 1000
        })
        this.myRes = res.data
        this.unsignlist = res.data
        this.unsignnum = res.data.length
        if (!this.hasAdd) {
          this.totalnum = this.signnum + this.unsignnum
          this.hasAdd = true
        }
        this.dialogTableVisible = false
        this.histogramData.arrdata = [this.signnum, this.unsignnum]
        this.pieData.arrdata.push([{ value: this.unsignnum, name: '未签到' }])
      } else {
        this.$message({
          message: res.message,
          type: 'error',
          duration: 1000
        })
      }
    },
    // 删除名单
    handleDelete(index, row) {
      this.$confirm('确认删除？')
        .then(async _ => {
          const { data: res } = await this.$http.get('class/deleteListFile', {
            params: {
              uid: window.sessionStorage.getItem('uid'),
              id: row.id,
              filePath: row.filePath
            }
          })
          if (res.code == 200) {
            this.$message({
              message: res.message,
              type: 'success',
              duration: 1000
            })
          } else {
            this.$message({
              message: res.message,
              type: 'error',
              duration: 1000
            })
          }
          this.getHistoryList()
          done()
        })
        .catch(_ => { })
    },

    // 导出
    // 获取数据
    async fetchData() {
      this.listLoading = true
      const { data: files } = await this.$http.get(
        'rec/excel',
        {
          params: {
            lid: this.lid,
            noSignIn: this.myRes
          }
        },
        { responseType: 'blob' }
      )
    },

    readerData(rawFile) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => {
          const data = e.target.result
          const workbook = XLSX.read(data, { type: 'array' })
          const firstSheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[firstSheetName]
          const header = this.getHeaderRow(worksheet)
          const results = XLSX.utils.sheet_to_json(worksheet)
          this.generateData({ header, results })
          this.loading = false
          resolve()
        }
        reader.readAsArrayBuffer(rawFile)
      })
    },

    generateData({ header, results }) {
      this.excelData.header = header
      this.excelData.results = results
      this.onSuccess && this.onSuccess(this.excelData)
    },

    getHeaderRow(sheet) {
      const headers = []
      const range = XLSX.utils.decode_range(sheet['!ref'])
      let C
      const R = range.s.r
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]
        /* find the cell in the first row */
        let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
        headers.push(hdr)
      }
      return headers
    },

    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleDownload() {
      if (this.multipleSelection.length) {
        this.downloadLoading = true
        import('@/vendor/Export2Excel').then(excel => {
          const tHeader = ['Id', 'Name', 'Role', 'Sex', 'Age', 'Address']
          const filterVal = ['id', 'name', 'role', 'sex', 'age', 'address']
          const list = this.multipleSelection
          const data = this.formatJson(filterVal, list)
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: this.filename,
            autoWidth: this.autoWidth,
            bookType: this.bookType
          })
          this.$refs.multipleTable.clearSelection()
          this.downloadLoading = false
        })
      } else {
        this.$message({
          message: 'Please select at least one item',
          type: 'warning'
        })
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },

    handleSuccess2({ results, header }) {
      this.tableData = results
      this.tableHeader = header
    },

    handleDateChange() {
      this.getData()
    },

    async handleSignInWithId(studentId) {
      try {
        const { data: res } = await this.$http.post('/act/startSignin', {
          studentId: studentId,
          lid: this.lid
        })

        if (res.code === 200) {
          this.$message.success('签到成功')
          await this.getSignList() // Refresh the list
        } else {
          this.$message.error(res.message || '签到失败')
        }
      } catch (error) {
        console.error('Sign in error:', error)
        this.$message.error('签到失败')
      }
    },

    getDropdownClass(state) {
      switch (state) {
        case '迟到':
          return 'drop-late'
        case '旷课':
        case '未认证':
          return 'drop-custom'
        case '请假':
        case '其他':
          return 'drop-qj'
        default:
          return 'drop-replace'
      }
    },

    getStatusClass(state) {
      switch (state) {
        case '迟到':
          return 'status-late'
        case '旷课':
          return 'status-absent'
        case '未认证':
          return 'status-unverified'
        case '请假':
          return 'status-leave'
        case '其他':
          return 'status-other'
        default:
          return 'status-replace'
      }
    },

    getStatusText(state) {
      switch (state) {
        case '迟到':
          return '迟到'
        case '旷课':
          return '旷课'
        case '未认证':
          return '未认证'
        case '请假':
          return '请假'
        case '其他':
          return '其他'
        default:
          return '未知状态'
      }
    }
  }
}
</script>

<style scoped>
/* 上传文件 */
.icon_style {
  margin: 0 0 7px !important;
}

.result-container {
  height: 100%;
  /* 版本1 */
  /* margin-top: 40px;
  margin-left: 120px;
  margin-right: 120px; */
}

.total-layout {
  margin-top: 20px;
}

.total-frame {
  border: 1px solid #dcdfe6;
  padding: 20px;
  height: 100px;
}

.total-icon {
  color: #409eff;
  width: 60px;
  height: 60px;
}

.total-title {
  position: relative;
  font-size: 16px;
  color: #909399;
  left: 70px;
  top: -67px;
}

.total-value {
  position: relative;
  font-size: 18px;
  color: #606266;
  left: 70px;
  top: -54px;
}

.un-handle-layout {
  margin-top: 20px;
  border: 1px solid #dcdfe6;
}

.layout-title {
  color: #606266;
  padding: 15px 20px;
  background: #f2f6fc;
  font-weight: bold;
}

.un-handle-content {
  padding: 20px 40px;
}

.un-handle-item {
  border-bottom: 1px solid #ebeef5;
  padding: 10px;
}

.overview-layout {
  margin-top: 20px;
}

.overview-item-value {
  font-size: 24px;
  text-align: center;
}

.overview-item-title {
  margin-top: 10px;
  text-align: center;
}

.out-border {
  border: 1px solid #dcdfe6;
}

.statistics-layout {
  border: 1px solid #dcdfe6;
}

.mine-layout {
  position: absolute;
  right: 140px;
  top: 107px;
  width: 250px;
  height: 235px;
}

.address-content {
  padding: 20px;
  font-size: 18px;
}

.excel-upload-input {
  display: none;
  z-index: -9999;
}

.drop {
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}

.chart-wrapper {
  background: #fff;
  padding: 16px 16px 0;
  margin-bottom: 32px;
}

@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}

.signlistclass {
  display: flex;
  justify-content: space-between;
  padding: 10px 36px;
}

.imgrightspan {
  vertical-align: 50%;
  margin-left: 25px;
}

.timeclass {
  line-height: 45px;
}

/* 代签到菜单 */
.drop-replace /deep/ .el-button--primary {
  background-color: #409eff;
  border-color: #409eff;
}

/* 迟到 下拉菜单 */
.drop-late /deep/ .el-button--primary {
  background-color: #e6a23c;
  border-color: #e6a23c;
}

/* 旷课和未认证 */
.drop-custom /deep/ .el-button--primary {
  background-color: #f56c6c;
  border-color: #f56c6c;
}

/* 请假和其他 */
.drop-qj /deep/ .el-button--primary {
  background-color: #67c23a;
  border-color: #67c23a;
}

.state-tag {
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  color: white;
  background-color: #909399;
}

.status-tag {
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  color: white;
  margin-right: 15px;
}

/* 迟到状态 */
.status-late {
  background-color: #e6a23c;
  /* 橙色 */
}

/* 旷课状态 */
.status-absent {
  background-color: #f56c6c;
  /* 红色 */
}

/* 未认证状态 */
.status-unverified {
  background-color: #909399;
  /* 灰色 */
}

/* 请假状态 */
.status-leave {
  background-color: #67c23a;
  /* 绿色 */
}

/* 其他状态 */
.status-other {
  background-color: #409eff;
  /* 蓝色 */
}

/* 默认状态 */
.status-replace {
  background-color: #909399;
  /* 灰色 */
}
</style>
