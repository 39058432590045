<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize' // 实现自适应大小

const animationDuration = 6000

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '80%'
    },
    height: {
      type: String,
      default: '300px'
    },
    barData: {
      type: Object
    }
  },
  data() {
    return {
      chart: null
    }
  },
  // 这里实现监听barData，一旦变化，重新绘制图
  watch: {
    barData: {
      deep: true,
      handler(val) {
        // console.log(val)
        this.setOptions(val)
      }
    }
  },
  mounted() {
    // console.log("barData:",this.barData)
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    setOptions({ xdata, arrdata } = {}) {
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: 10,
          left: '2%',
          right: '2%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xdata,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            // 设置纵坐标间隔至少为1
            minInterval: 1,
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '人数',
            type: 'bar',
            itemStyle: {
              normal: {
                // 设置每个柱线不同的颜色
                color: function(params) {
                  var colorList = ['#2DC1C3', '#5CC0E3']
                  return colorList[params.dataIndex]
                }
              }
            },
            stack: 'vistors',
            barWidth: '50px',
            data: arrdata,
            animationDuration
          }
        ]
      })
    },
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')

      this.setOptions(this.barData)
      // this.chart.setOption({
      //   tooltip: {
      //     trigger: 'axis',
      //     axisPointer: {
      //       // 坐标轴指示器，坐标轴触发有效
      //       type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
      //     },
      //   },
      //   grid: {
      //     top: 10,
      //     left: '2%',
      //     right: '2%',
      //     bottom: '3%',
      //     containLabel: true,
      //   },
      //   xAxis: [
      //     {
      //       type: 'category',
      //       data: ['已签到', '未签到'],
      //       axisTick: {
      //         alignWithLabel: true,
      //       },
      //     },
      //   ],
      //   yAxis: [
      //     {
      //       type: 'value',
      //       axisTick: {
      //         show: false,
      //       },
      //     },
      //   ],
      //   series: [
      //     {
      //       name: 'pageA',
      //       type: 'bar',
      //       stack: 'vistors',
      //       barWidth: '60%',
      //       data: [28, 12],
      //       animationDuration,
      //     },
      //     // , {
      //     //   name: 'pageB',
      //     //   type: 'bar',
      //     //   stack: 'vistors',
      //     //   barWidth: '60%',
      //     //   data: [80, 52, 200, 334, 390, 330, 220],
      //     //   animationDuration
      //     // }, {
      //     //   name: 'pageC',
      //     //   type: 'bar',
      //     //   stack: 'vistors',
      //     //   barWidth: '60%',
      //     //   data: [30, 52, 200, 334, 390, 330, 220],
      //     //   animationDuration
      //     // }
      //   ],
      // })
    }
  }
}
</script>
