<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb2">
      <el-breadcrumb-item>审核申请</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="righttable">
      <i class="el-icon-postcard"></i>
      <span style="margin-left:10px;">我的组织</span>
      <el-table
        :data="organizationHistory"
        style="width:100%;margin-top:20px;"
        fit
        border
        stripe
      >
        <el-table-column
          label="序号"
          type="index"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          label="组织码"
          prop="ocode"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          label="组织单位名称"
          prop="oname"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          label="创建时间"
          prop="createtime"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column label="操作" align="center" min-width="100">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="handleApplyList(scope.row)"
              >查看申请</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next,jumper"
          :current-page.sync="formInline.pageNum"
          :page-size="formInline.pageSize"
          :page-sizes="[5, 10, 15]"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import qs from 'qs'
export default {
  data() {
    return {
      uid: '', //用户id
      organizationHistory: [],
      formInline: {
        pageNum: 1,
        pageSize: 5
      },
      total: 0,
      rules: {
        phoneNumber: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          {
            pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
            message: '请输入正确的电话号码',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change']
          }
        ],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }],
        name: [{ required: true, message: '请输入组织名称', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.uid = window.sessionStorage.getItem('uid')
  },
  mounted() {
    this.getList()
  },
  methods: {
    //查看申请列表
    handleApplyList(row) {
      this.$router.push({
        path: '/checkCommonApply2/checkCommonApplyList',
        query: { oid: row.oid }
      })
    },
    //获取组织列表
    async getList() {
      const { data: res } = await this.$http.get('/org/selectbyuid', {
        params: {
          uid: this.uid,
          pageNum: this.formInline.pageNum,
          pageSize: this.formInline.pageSize
        }
      })
      if (res != null) {
        this.organizationHistory = res.data
        this.total = res.total
      }
    },
    //更改分页大小
    handleSizeChange(val) {
      this.formInline.pageNum = 1
      this.formInline.pageSize = val
      this.getList()
    },
    //更改到第几页
    handleCurrentChange(val) {
      this.formInline.pageNum = val
      this.getList()
    }
  }
}
</script>

<style scoped>
.createdInput {
  width: 300px;
}
.el-dialog {
  box-sizing: border-box;
}
.el-dialog__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.el-dialog__header {
  padding: 20px 20px 10px;
  text-align: center;
}

.el-dialog__body {
  padding: 20px;
}

.el-dialog__footer {
  padding: 10px 20px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
