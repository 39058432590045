<template>
  <div class="total">
    <div class="main">
      <div><h2 style="display:inline">课程名称：{{className}}</h2></div>
      <span v-if="isShow" class="countTime" style="color: #ed1c24" >
          剩余：{{ min }} 分 {{ sec }} 秒
      </span>
      <span v-else-if="signStopType==0" class="countTime">本次环节已结束！请联系管理员签到！</span>
      <div class="qrcode">
        <div v-show="status != 0"  ref="qrCodeUrl" class="qrcodechild"></div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
var qrcode
export default {
    
  data() {
    return {
      code:'',
      lid: '',
      uid: '',
      min: '',
      sec: '',
      finalTime:'',
      status:'',
      isShow: false,
      sct:'',
      token:'',
      className:'',
      signStopType:0
    }
  },
  mounted() {
    this.screenWidth = document.body.clientWidth
    this.screenHeight = document.body.clientHeight
    this.lid = this.$route.query.lid,
    this.uid = this.$route.query.uid,
    this.finalTime=this.$route.query.EndTime,
    this.status = this.$route.query.status
    this.token = this.$route.query.token
    this.className = this.$route.query.className
    this.signStopType = this.$route.query.signStopType
    console.log(this.signStopType)
    this.creatQrCode()
    this.updateCode()
    this.countdown()
  },
  methods: {
    // 倒计时
    countdown: function () {
      console.log("xianzai time",this.finalTime)
      const end = Date.parse(this.finalTime)
      const now = Date.parse(new Date())
      const msec = end - now
      let min = parseInt((msec / 1000 / 60) % 60)
      let sec = parseInt((msec / 1000) % 60)
       console.log("现在的状态：",this.status)
      // 签到进行中
      if (this.status == 1) {
       
        if ((min > 0 && sec >= 0) || (min == 0 && sec > 0)) {
          this.isShow = true
        } else {
          this.isShow = false
        }
        if (min <= 0 && sec <= 0) {
          this.status = 0
        }
      }
      this.min = min > 9 ? min : '0' + min
      this.sec = sec > 9 ? sec : '0' + sec
      const that = this
      this.sct = setTimeout(function () {
        that.countdown()
      }, 1000)
    },
    end() {
      clearTimeout(this.sct) //清除
    },
    updateCode() {
      var times = 0
      var that = this
      var i = setInterval(async function () {
        if (times == 0) {
          await that.getUpdateCode()
          qrcode.makeCode(that.code)
        }
        times++
        if (times == 10) {
          times = 0
        }
      }, 1000)
      this.timer = i
    },
    creatQrCode() {
      qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.code,
        image: '/src/assets/default_head_circle.png',
        width: this.screenWidth * 0.26,
        height: this.screenWidth * 0.26,
        colorDark: '#222',
        colorLight: 'white',
        correctLevel: QRCode.CorrectLevel.H,
      })
      this.qrcode = qrcode
    },
    // 实时获取二维码数据
    async getUpdateCode() {
      // 更新数据
      const { data: res } = await this.$http.get('/act/updateCode', {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          lid: this.lid,
        },
      })
      // 失败
      if (res.code != 200) {
        this.$message.error('获取信息失败')
        return false
      } else {
          console.log("现在的code",res.data.code)
        this.code = res.data.code
      }
    },
  },
}
</script>

<style scoped>
  page {
    margin: 0;
    padding: 0;
  }
  .total{
    background-image: url('https://img-blog.csdnimg.cn/20201224163951278.png?x-oss-process=image/watermark,type_ZmFuZ3poZW5naGVpdGk,shadow_10,text_aHR0cHM6Ly9ibG9nLmNzZG4ubmV0L3loMDAxNg==,size_16,color_FFFFFF,t_70#pic_center');
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
    background-attachment: fixed;
    z-index: -2;
  }
  .main{
    width: 700px;
    height: 600px;
    margin:0 auto;
    /* border:2px solid; */
    text-align: center;
    align-content: center;
    padding-top: 70px;
  }
  .qrcode{
    width: 415px;
    height: 415px;
    margin: 0 auto;
    /* border: 2px solid; */
    background-color: rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    position: relative;
  }
  .qrcodechild{
    border: 8px solid white;
    border-radius: 5px;
    box-shadow: 0 0 6px 6px rgba(0,0,0,0.2);
  }
  .countTime{
    display: block;
    margin-top: 20px;
  }
</style>