<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="home-breadcrumb">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
    </el-breadcrumb>
    <!--筛选搜索-->
    <el-card class="filter-container">
      <div>
        <i class="el-icon-search"></i>
        <span>筛选搜索</span>
      </div>

      <div style="margin-top: 5px">
        <el-form
          :inline="true"
          :model="formInline"
          size="small"
          label-width="140px"
          class="demo-form-inline"
        >
          <el-row style="display:flex">
            <el-col :span="5"></el-col>
            <el-col :span="8">
              <el-form-item label="发起人:" label-width="180px">
                <el-input
                  v-model="formInline.name"
                  placeholder
                  @keydown.enter.native="onSubmit"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1.1">
              <el-form-item>
                <el-button
                  style="float: right; margin-right: 15px"
                  @click="handleResetSearch()"
                  size="small"
                  >重置</el-button
                >
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <!--列表-->
    <el-card class="table-container">
      <i class="el-icon-tickets"></i>
      <span>发起人列表</span>
      <el-table
        :data="signData"
        border
        stripe
        style="width: 100%"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column prop="avatar" label="头像" align="center">
          <template slot-scope="scope">
            <el-avatar :size="35" :src="scope.row.avatar"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="发起人" sortable align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.name }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="sex" label="性别" sortable align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.sex }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="age" label="年龄" sortable align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.age }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="email" label="邮箱" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.email }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="地址" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.address }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="university" label="学校" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.university }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="academy" label="学院" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.academy }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="address" width="200" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="handleShow(scope.$index, scope.row)"
              >签到活动</el-button
            >
            <el-button
              v-if="role == -1"
              type="warning"
              size="mini"
              @click="gotoMemberlist(scope.$index, scope.row)"
              >签到名单</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next,jumper"
          :current-page.sync="formInline.pageNum"
          :page-size="formInline.pageSize"
          :page-sizes="[5, 10, 15]"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
const defaultFormInline = {
  pageNum: 1,
  pageSize: 10,
  university: null,
  academy: null,
  name: null
}
export default {
  name: 'home',
  data() {
    return {
      signlist: [],
      signData: [],
      formInline: {
        name: '',
        pageNum: 1,
        pageSize: 10
      },
      role: 0,
      // 分页
      total: 0,
      value1: true
    }
  },
  created() {
    this.selectList()
    this.role = sessionStorage.getItem('role')
  },
  mounted: function() {
    // this.selectList()
  },
  methods: {
    // 实现分页
    // 更改了每页大小
    handleSizeChange(val) {
      this.formInline.pageNum = 1
      this.formInline.pageSize = val
      // console.log("val",val)
      // 请求对应的分页数据
      this.selectList()
    },
    // 更改了第几页
    handleCurrentChange(val) {
      this.formInline.pageNum = val
      this.selectList()
    },

    // 重置前往签到名单搜索表单
    handleResetSearch() {
      this.formInline = Object.assign({}, defaultFormInline)
    },
    //前往签到名单
    gotoMemberlist(index, row) {
      sessionStorage.setItem('teacheruid', row.uid)
      this.$router.push({ path: 'memberList', query: { uid: row.uid } })
    },
    handleShow(index, row) {
      this.$router.push({ path: 'signList', query: { tid: row.uid } })
    },
    // 跳转到签到进程
    handleViewOrder(index, row) {
      this.$router.push({ path: '/home/signDetail', query: { id: row.id } })
    },

    // 跳转到签到结果
    handleResultOrder(index, row) {
      this.$router.push({ path: '/home/signResult', query: { id: row.id } })
    },

    async onSubmit() {
      await this.selectList()
    },
    // 查询签到发起人
    async selectList() {
      const { data: res } = await this.$http.get('auth/selectList', {
        params: {
          name: this.formInline.name,
          university: this.formInline.university,
          academy: this.formInline.academy,
          pageNum: this.formInline.pageNum,
          pageSize: this.formInline.pageSize
        }
      })
      if (res != null) {
        this.$message.success('查询成功！')
        this.signData = res.User
        this.total = res.total
      }
    }
  }
}
</script>

<style scoped>
.app-container {
  width: 98%;
  height: 100vh;
  padding-bottom: 300px;
  overflow: auto!important;
}
.input-width {
  width: 203px;
}
.home-breadcrumb {
  position: relative;
  top: -52px;
  left: 29px;
}
</style>
