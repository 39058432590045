<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb2">
      <el-breadcrumb-item>组织考勤统计</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div class="title-name">
        <span>{{ fatherName ? fatherName : '考勤总览' }}</span>
      </div>
    </el-card>
    <el-card>
      <div id="organizationChart">
        <div class="chart-container">
          <!-- 左侧表单部分 -->
          <el-card class="form-card">
            <div class="form-title">考勤查询</div>
            <el-form :model="digitalForm" ref="digitalForm" label-position="left">
              <el-form-item label="学年" prop="year">
                <el-select v-model="digitalForm.year" placeholder="请选择学年">
                  <el-option v-for="year in years" :key="year" :label="year" :value="year"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学期" prop="semester">
                <el-select v-model="digitalForm.semester" placeholder="请选择学期">
                  <el-option label="上学期" value="上"></el-option>
                  <el-option label="下学期" value="下"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="周次" prop="week">
                <!-- 周选择 -->
                <el-select v-model="digitalForm.startweek" placeholder="选择开始周" style="width:80px;">
                  <el-option v-for="week in weeks" :key="week" :label="week" :value="week" />
                </el-select>---
                <el-select v-model="digitalForm.endweek" placeholder="选择结束周" style="width:80px;">
                  <el-option v-for="week in weeks" :key="week" :label="week" :value="week" />
                </el-select>
              </el-form-item>
              <el-form-item label="节次">
                <!-- 节次选择 -->
                <el-select v-model="digitalForm.section" placeholder="选择节次">
                  <el-option v-for="option in sectionOptions" :key="option" :label="option" :value="option" />
                </el-select>
              </el-form-item>
              <el-button type="primary" @click="getStatics" class="query-btn">查询统计</el-button>
            </el-form>
          </el-card>

          <!-- 右侧图表部分 -->
          <el-card class="chart-card">

            <div class="charts-wrapper">
              <div ref="pieChart" class="pie-chart" />
              <div class="detail-item">
                <el-progress type="dashboard" :percentage="attendanceRate" :color="getDashboardColor">
                  <template #default="{ percentage }">
                    <span class="percentage-value">{{ percentage }}%</span>
                    <span class="percentage-label">考勤率</span>
                  </template>
                </el-progress>
                <!-- <div class="total-number">总人数：{{ totalCount }}人</div> -->
                <div class="attendance-rate">
                  到课率：{{ attendanceRate }}%
                  <!-- <span :class="getRateClass" style="font-weight: 800;">{{ getRateLevel }}</span> -->
                </div>
              </div>
            </div>
            <div class="total-info">
              <el-button type="primary" @click="viewSubDetails" class="view-details-btn">查看下级详情</el-button>
            </div>
          </el-card>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import * as echarts from 'echarts'
require('echarts/theme/macarons')
import ResizeMixin from '@/views/home/components/mixins/resize'
export default {
  mixins: [ResizeMixin],
  data() {
    return {
      oid: '',
      fatherName: '',
      childName: '',
      digitalForm: {
        year: '2024',
        semester: '上',
        startweek: '周一',
        endweek: '周五',
        section: '上午'
      },
      years: ['2023', '2024', '2025', '2026', '2027'],
      weeks: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
      sectionOptions: ['早八', '上午', '下午', '晚上', '全天'],// 节次选项
      lineChart: null,
      chartData: {
        xAxisData: ['签到率', '迟到率', '请假率', '旷课率', '其他'],
        seriesData: [80, 70, 60, 50, 40] // 示例数据
      },
      pieData: [{ value: 1, name: '已签到' }],
      statisticsData: {},
      totalCount: 0,
      attendanceRate: 0
    }
  },
  computed: {
    getRateLevel() {
      if (this.attendanceRate >= 90) return '优秀'
      if (this.attendanceRate >= 75) return '良好'
      if (this.attendanceRate >= 60) return '合格'
      return '不合格'
    },
    getRateClass() {
      return {
        'rate-excellent': this.attendanceRate >= 90,
        'rate-good': this.attendanceRate >= 75 && this.attendanceRate < 90,
        'rate-pass': this.attendanceRate >= 60 && this.attendanceRate < 75,
        'rate-fail': this.attendanceRate < 60
      }
    },
    getDashboardColor() {
      if (this.attendanceRate >= 90) return '#67c23a'  // 优秀 - 绿色
      if (this.attendanceRate >= 75) return '#409eff'  // 良好 - 蓝色
      if (this.attendanceRate >= 60) return '#e6a23c'  // 合格 - 橙色
      return '#f56c6c'  // 不合格 - 红色
    }
  },
  created() {
    this.uid = window.sessionStorage.getItem('uid'),
      this.childName = window.sessionStorage.getItem('childName'),
      this.oid = this.$route.query.oid;
    this.fatherName = this.$route.query.fatherName;
    this.initData()
    console.log('uid:', this.uid)
    console.log('fatherName:', this.fatherName)
    console.log('childName:', this.childName)
    console.log('create_oid:', this.oid);

  },
  mounted() {
    // this.initLineChart()
    this.initPieChart()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },

    initData() {
      this.uid = sessionStorage.getItem('uid')
      this.fatherName = this.$route.query.fatherName
      this.soid = this.$route.query.oid
    },

    initPieChart() {
      // 初始化饼状图
      const pieChart = echarts.init(this.$refs.pieChart, 'macarons');
      // 饼状图配置
      const pieOption = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          // orient: 'vertical',
          left: 'center',
          data: this.pieData.map(item => item.name) // 假设this.pieData是包含name属性的对象数组
        },
        series: [
          {
            name: '考勤情况', // 系列名称
            type: 'pie', // 图表类型为饼图
            radius: '50%', // 饼图的半径，这里设置为容器的50%
            center: ['50%', '50%'], // 饼图的中心位置这里设置为容器的中心
            data: this.pieData, // 饼图的数据
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)' // 鼠标悬浮时的阴影效果
              }
            },
            animationEasing: 'cubicInOut', // 动画的缓动效果
            animationDuration: 2600 // 动画的持续时间，单位为毫秒
          }
        ]
      };
      // 设置饼状图配置
      pieChart.setOption(pieOption);
    },
    async getStatics() {
      try {
        const { data: res } = await this.$http.get('/org/statisticorganization', {
          params: {
            oid: this.oid,
            year: this.digitalForm.year,
            semester: this.digitalForm.semester,
            startweek: this.digitalForm.startweek,
            endweek: this.digitalForm.endweek,
            section: this.digitalForm.section
          }
        })

        if (res.code === 200) {
          this.statisticsData = res.data[0]
          this.totalCount = res.data[0]['总人数']
          // 新增判断
          if (this.totalCount === 0) {
            this.$message.warning('无考勤记录')
            return; // 终止后续操作
          }

          this.attendanceRate = Math.round((res.data[0]['已签到'] / this.totalCount) * 100)

          // // 更新饼图数据
          // this.pieData = Object.entries(res.data[0])
          //   .filter(([key]) => key !== '总人数')
          //   .map(([name, value]) => ({ name, value }))

          const signedCount = this.statisticsData['已签到'];

          // 计算旷课人数
          const absentCount = this.totalCount - signedCount;

          // 更新饼图数据，只保留需要的字段
          this.pieData = [
            { name: '按时签到', value: this.statisticsData['签到'] },
            { name: '旷课', value: absentCount },
            { name: '请假', value: this.statisticsData['请假'] },
            { name: '迟到', value: this.statisticsData['迟到'] },
            { name: '其他', value: this.statisticsData['其他'] }
          ];

          this.initPieChart()
        } else {
          this.$message.error(res.message || '查询失败')
        }
      } catch (error) {
        this.$message.error('网络请求失败')
      }
    },
    viewSubDetails() {
      if (!this.fatherName) {
        this.$router.push({
          path: '/zuzhi/checkAttendance',
          query: {
            uid: this.uid,
            fatherName: this.fatherName,
            childName: this.childName
          }
        })
      } else {
        this.$router.push({
          path: '/zuzhi/organizationList/myOrganizationList1',
          query: {
            oid: this.soid,
            fatherName: this.fatherName
          }
        })
      }
    },
    calculatePercentage(value) {
      return Math.round((value / this.totalCount) * 100);
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  padding: 20px;

  .title-name {
    text-align: center;

    span {
      font-size: 30px;
      font-weight: bold;
    }
  }

  .chart-container {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }

  .form-card {
    width: 350px;

    .form-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
      color: #303133;
    }
  }

  .chart-card {
    flex: 1;

    .total-info {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-bottom: 20px;
      font-size: 16px;
    }

    .charts-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .pie-chart {
        width: 50%;
        height: 400px;
      }

      .detail-item {
        width: 30%;
      }
    }
  }

  .period-select {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .query-btn {
    margin-left: 100px;
    margin-top: 20px;
  }

  .rate-excellent {
    color: #67c23a;
  }

  .rate-good {
    color: #409eff;
  }

  .rate-pass {
    color: #e6a23c;
  }

  .rate-fail {
    color: #f56c6c;
  }
}
</style>
