<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb2">
      <el-breadcrumb-item :to="{ path: '/zuzhi/checkAttendance' }">下级列表</el-breadcrumb-item>
      <el-breadcrumb-item>我的组织详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- <el-button
      style="margin-top:0px;margin-bottom: 2px;font-size:12px"
      type="primary"
      icon="el-icon-arrow-left"
      @click="handleGoBack"
      >返回</el-button
    > -->
    <div id="frozen-btn">
      <button @click="handleGoBack" class="green">
        返回
      </button>
    </div>
    <!--当前权限等级-->
    <el-card class="right-name">
      <div style="font-size:30px;font-weight:bold;" class="course-title">
        {{ fatherName }}
      </div>
    </el-card>
    <!--绑定的下级-->
    <el-card class="righttable right-lay">
      <i class="el-icon-user"></i>
      <span>我的下级</span>
      <div class="search-bar">
        <el-input v-model="formInlineOrg.oname" placeholder="请输入组织名或课程名进行查询" style="width:240px;" size="small"
          class="inputstyle" @keydown.enter.native="searchOrg" prefix-icon="el-icon-search">
        </el-input>
        <el-select v-model="searchCategory" placeholder="搜索类别" style="width: 100px" size="small" class="input-select">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <el-button plain type="primary" size="small" @click="clearSearch">重置</el-button>
        <el-button type="primary" size="small" @click="searchOrg">查询</el-button>
      </div>
      <div class="right-up">
        <!-- <i class="el-icon-tickets"></i>
        <span>组织申请列表</span> -->
        <el-table :data="listData" border stripe style="width: 100%"
          :default-sort="{ prop: 'date', order: 'descending' }">
          <el-table-column label="序号" type="index" align="center" min-width="100"></el-table-column>
          <el-table-column prop="uname" label="管理员" min-width="100" align="center">
          </el-table-column>
          <el-table-column label="类型" prop="goupType" min-width="100" align="center">
            <template slot-scope="scope">
              <el-tag type="info" light v-if="scope.row.groupType === 2">组织</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="组织名称" prop="oname" min-width="100" align="center"></el-table-column>
          <el-table-column prop="phone" label="管理员电话" min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="application_status" sortable label="操作" align="center" min-width="250">
            <template slot-scope="scope">
              <el-button plain type="success" size="mini" @click="handleLower(scope.$index, scope.row)">考勤统计</el-button>
              <el-button type="primary" size="mini" @click="viewSubDetails(scope.$index, scope.row)">查看下级</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination background @size-change="handleOrgSizeChange" @current-change="handleOrgCurrentChange"
            layout="total, sizes, prev, pager, next,jumper" :current-page.sync="formInlineOrg.pageNum"
            :page-size="formInlineOrg.pageSize" :page-sizes="[5, 10, 15]" :total="orgTotal"></el-pagination>
        </div>
      </div>
      <div class="right-down">
        <!-- <i class="el-icon-tickets"></i>
        <span>课程申请列表</span> -->
        <el-table :data="couListData" border stripe style="width: 100%"
          :default-sort="{ prop: 'date', order: 'descending' }">
          <el-table-column label="序号" type="index" align="center" min-width="100"></el-table-column>
          <el-table-column prop="uname" label="教师" min-width="100" align="center">
          </el-table-column>
          <el-table-column label="类型" prop="goupType" min-width="100" align="center">
            <template slot-scope="scope">
              <el-tag type="primary" light v-if="scope.row.groupType === 1">课程</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="课程名称" prop="name" min-width="100" align="center"></el-table-column>
          <el-table-column prop="week" label="周次" min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="section" label="节数" min-width="100" align="center"></el-table-column>
          <el-table-column prop="application_status" sortable label="操作" align="center" min-width="350">
            <template slot-scope="scope">
              <el-button plain type="success" size="mini"
                @click="handleStatistics(scope.$index, scope.row)">考勤统计</el-button>
              <el-button plain type="primary" size="mini"
                @click="handleHistory(scope.$index, scope.row)">查看历史考勤</el-button>
              <el-button type="primary" size="mini" @click="handleCurrent(scope.$index, scope.row)">查看正在签到</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination background @size-change="handleCouSizeChange" @current-change="handleCouCurrentChange"
            layout="total, sizes, prev, pager, next,jumper" :current-page.sync="formInlineCou.pageNum"
            :page-size="formInlineCou.pageSize" :page-sizes="[5, 10, 15]" :total="couTotal"></el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import qs from 'qs'
// const defaultFormInline = {
//   pageNum: 1,
//   pageSize: 5,
//   oname: null,
//   name: null
// }
export default {
  data() {
    return {
      options: [
        {
          value: 'organization',
          label: '组织'
        },
        {
          value: 'course',
          label: '课程'
        }
      ],
      searchCategory: '',
      //开放考勤结果情况
      resultKaoq: '开放考勤结果',
      nowRight: '',
      //上级单位
      bindUp: [],
      listData: [], //组织绑定列表
      couListData: [], //课程绑定列表
      //绑定历史
      bindHistorydialogVisible: false,
      bindHistoryList: [],
      //绑定历史  //分页
      formInlineOrg: {
        oname: '',
        pageNum: 1,
        pageSize: 5
      },
      formInlineCou: {
        pageNum: 1,
        pageSize: 5
      },
      formInline: {
        pageNum: 1,
        pageSize: 5
      },
      tid: null,
      uid: '',
      orgTotal: 0,
      couTotal: 0,
      //绑定申请对话框
      rightdialogVisible: false,
      applyForm: {
        value: []
      },
      myprop: {
        label: 'groupName',
        value: 'id'
      },
      soid: '', //父级组织的id
      fatherName: '',
      flag: '',
      //表单验证信息
      rules: {
        value: [
          { required: true, message: '请选择上级单位', trigger: 'change' }
        ]
      }
    }
  },
  created() {
    // this.oid = window.sessionStorage.getItem('soid')
    // this.oid = this.$route.query.oid
    // this.groupName = window.sessionStorage.getItem('fatherName')
    this.initData()
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        // 监听路由及传入值 判断是否重置进入此路由页面
        if (
          newVal?.query?.oid !== this.soid &&
          newVal.path === '/zuzhi/organizationList/myOrganizationList1'
        ) {
          // console.log('路由进入且值改变', newVal)
          // 路由值改变 重新初始化页面信息
          this.initData()
        }
      },
      immediate: true, // 立即执行处理程序
      deep: true
    }
  },
  methods: {
    //查看课程是否有正在签到活动
    isNowSign(row) {
      const { data: res } = this.$http.get('/rec/isresultTeacher', {
        params: {
          ccid: row.ccid
        }
      })
      if (res.code == 199) {
        this.$message.error('')
      }
    },
    // 页面初始化
    initData() {
      this.uid = sessionStorage.getItem('uid')
      this.fatherName = this.$route.query.fatherName
      this.soid = this.$route.query.oid
      this.getList()
      this.getCouList()
    },
    //重置
    clearSearch() {
      // this.formInlineOrg = Object.assign({}, defaultFormInline)
      this.formInlineOrg.oname = null
      this.searchCategory = null
      this.getList()
      this.getCouList()
    },
    //搜索
    async searchOrg() {
      if (!this.searchCategory) {
        this.$message({
          message: '请选择搜索类别',
          type: 'warning'
        })
        return // 阻止继续搜索操作
      }
      // 根据选中的搜索类别执行相应的搜索操作
      if (this.searchCategory === 'organization') {
        const { data: res } = await this.$http.get('/org/applybindbyoname', {
          params: {
            oname: this.formInlineOrg.oname,
            pageNum: this.formInlineOrg.pageNum,
            pageSize: this.formInlineOrg.pageSize,
            oid: this.soid
          }
        })
        if (res.data != null) {
          this.listData = res.data.map(e => {
            return {
              ...e,
              groupType: 2
            }
          })
          this.total = res.total
          this.$message.success('查询成功')
        }
      } else if (this.searchCategory === 'course') {
        const { data: res } = await this.$http.get('/cou/applybindcoubyname', {
          params: {
            name: this.formInlineOrg.oname,
            pageNum: this.formInlineCou.pageNum,
            pageSize: this.formInlineCou.pageSize,
            oid: this.soid
          }
        })
        if (res.data != null) {
          this.couListData = res.data.map(e => {
            return {
              ...e,
              groupType: 1
            }
          })
          this.total = res.total
          this.$message.success('查询成功')
        }
      }
    },
    // 返回上一级
    handleGoBack() {
      window.history.back()
    },
    //查看下级
    async handleLower(index, row) {
      const { data: res } = await this.$http.get('/org/whethersee', {
        params: {
          oid: row.oid, //要查看的下级组织
          sid: this.soid //本组织
        }
      })
      if (res.code === 200) {
        this.flag = 1
      }
      if (res.code === 113) {
        this.flag = 2
      }
      if (this.flag === 1) {
        // window.sessionStorage.setItem('groupCode', row.groupCode)
        // window.sessionStorage.setItem('fatherName', row.oname)
        // window.sessionStorage.setItem('cid', row.cid)
        // window.sessionStorage.setItem('acttype', row.actType)
        // window.sessionStorage.setItem('createtime', row.createTime)
        // window.sessionStorage.setItem('className', row.className)
        // window.sessionStorage.setItem('actId', row.actId)
        // window.sessionStorage.setItem('soid', row.oid)
        this.$router.push({
          path: '/zuzhi/organizationChart',
          query: { oid: row.oid, fatherName: row.oname }
        })
      } else {
        this.$message.error('下级关闭查看考勤情况')
      }
    },
    //原版本考勤统计[已废弃]
    handleStatistics(index, row) {
      if (row.groupType === 2) {
        this.$router.push({
          path: '/zuzhi/organizationChart',
          query: {
            oid: this.soid,
            fatherName: this.fatherName,
            childName: row.oname
          }
        })
      } else {
        this.$router.push({
          path: '/zuzhi/courseAttendance/courseAttendance1',
          query: {
            ccid: row.ccid,
            oid: this.soid,
            fatherName: this.fatherName,
            childName: row.name
          }
        })
      }
    },

    viewSubDetails(index, row) {
      console.log("点击了viewSub，oid=", row.oid, "father=", this.fatherName)
      if (!this.fatherName) {
        this.$router.push({
          path: '/zuzhi/checkAttendance',
          query: {
            uid: this.uid,
            oid: row.oid,
            fatherName: this.fatherName,
            childName: this.childName
          }
        })
      } else {
        this.$router.push({
          path: '/zuzhi/organizationList/myOrganizationList1',
          query: {
            oid: row.oid,
            fatherName: row.oname
          }
        })
      }
    },

    //查看历史考勤
    handleHistory(index, row) {
      // window.sessionStorage.setItem('ccid', row.ccid)
      // window.sessionStorage.setItem('curriculumName', row.curriculumName)
      this.$router.push({
        path: '/zuzhi/courseHistory/courseHistory1',
        query: {
          oid: this.soid,
          fatherName: this.fatherName,
          ccid: row.ccid,
          curriculumName: row.name
        }
      })
    },
    //正在签到
    handleCurrent(index, row) {
      this.$router.push({
        path: '/zuzhi/nowSign',
        query: {
          oid: this.soid,
          fatherName: this.fatherName,
          ccid: row.ccid
        }
      })
    },
    //解除下级绑定
    async closedown(mydata) {
      const { data: res } = await this.$http.post(
        '/org/untiebind',
        qs.stringify({
          soid: mydata.soid
        })
      )
      if (res.code == 200) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
        this.getDownList()
      } else {
        this.$message.error(res.message)
      }
    },

    async getList() {
      const { data: res } = await this.$http.get('/org/applybind', {
        params: {
          oid: this.soid,
          pageNum: this.formInlineOrg.pageNum,
          pageSize: this.formInlineOrg.pageSize
        }
      })
      if (res.code == 200) {
        this.listData = res.data.map(e => {
          return {
            ...e,
            groupType: 2
          }
        })
        this.orgTotal = res.total
      }
    },
    async getCouList() {
      const { data: res } = await this.$http.get('/cou/applybindcou', {
        params: {
          oid: this.soid,
          pageNum: this.formInlineCou.pageNum,
          pageSize: this.formInlineCou.pageSize
        }
      })
      if (res.code == 200) {
        this.couListData = res.data.map(e => {
          return {
            ...e,
            groupType: 1
          }
        })
        this.couTotal = res.total
      }
    },
    // 实现分页
    // 更改了每页大小
    handleSizeChange(val) {
      this.formInline.pageNum = 1
      this.formInline.pageSize = val
      this.getHistoryList()
    },
    // 更改了第几页
    handleCurrentChange(val) {
      this.formInline.pageNum = val
      this.getHistoryList()
    },
    // 实现组织分页
    // 更改了每页大小
    handleOrgSizeChange(val) {
      this.formInlineOrg.pageNum = 1
      this.formInlineOrg.pageSize = val
      this.getList()
    },
    // 更改了第几页
    handleOrgCurrentChange(val) {
      this.formInlineOrg.pageNum = val
      this.getList()
    },
    // 实现课程分页
    // 更改了每页大小
    handleCouSizeChange(val) {
      this.formInlineCou.pageNum = 1
      this.formInlineCou.pageSize = val
      this.getCouList()
    },
    // 更改了第几页
    handleCouCurrentChange(val) {
      this.formInlineCou.pageNum = val
      this.getCouList()
    }
  }
}
</script>

<style lang="scss" scoped>
#frozen-btn {
  button {
    border: 0;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 10px;
    border-radius: 4px;
    color: white;
    outline: none;
    position: relative;
    margin-bottom: 5px;
  }

  button:before {
    content: '';
    display: block;
    background: linear-gradient(to left,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 0.4) 50%);
    background-size: 210% 100%;
    background-position: right bottom;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 4px;
    transition: all 1s;
    -webkit-transition: all 1s;
  }

  .green {
    background-image: linear-gradient(to right, #25aae1, #40e495);
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
  }

  .green:hover:before {
    background-position: left bottom;
  }
}

.search-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;

  .inputstyle {
    margin-right: 10px;
  }

  .input-select {
    margin-right: 5px;
  }
}

.course-title {
  text-align: center;
}
</style>
