<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/zuzhi/checkAttendance' }">考勤情况</el-breadcrumb-item>
      <el-breadcrumb-item :to="{
        path: '/zuzhi/organizationList/myOrganizationList1',
        query: {
          oid: this.oid,
          ccid: this.ccid,
          actId: this.actId,
          createtime: this.createtime,
          fatherName: this.fatherName,
          curriculumName: this.curriculumName
        }
      }">我的组织详情</el-breadcrumb-item>

      <el-breadcrumb-item :to="{
        path: '/zuzhi/courseHistory/courseHistory1',
        query: {
          oid: this.oid,
          ccid: this.ccid,
          actId: this.actId,
          createtime: this.createtime,
          fatherName: this.fatherName,
          curriculumName: this.curriculumName
        }
      }">课程签到活动</el-breadcrumb-item>
      <el-breadcrumb-item>签到环节</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="operate-container">
      <div class="course-title">
        <span class="title">{{ curriculumName }}</span>
        <span class="time">创建时间：{{ createtime }}</span>
      </div>
    </el-card>
    <el-card class="table-container">
      <i class="el-icon-tickets"></i>
      <span>签到环节列表</span>
      <el-table :data="signData" border stripe style="width: 100%"
        :default-sort="{ prop: 'date', order: 'descending' }">
        <el-table-column prop="linkType" label="签到环节" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.linkType == 1 ? '签到' : '签退' }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="startTime" label="开始时间" sortable align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.startTime }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="endTime" label="结束时间" sortable align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.endTime }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="签到时限" sortable align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.duration }}分钟</p>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="激活状态" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.status | formatStatus }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="address" width="300" label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" @click="handleResultOrder(scope.$index, scope.row)">签到结果</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next,jumper" :current-page.sync="formInline.pageNum"
          :page-size="formInline.pageSize" :page-sizes="[5, 10, 15]" :total="total"></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import qs from 'qs'
const defaultFormInline = {
  pageNum: 1,
  pageSize: 10,
  university: null,
  academy: null,
  name: null
}
export default {
  name: 'signlinkList',
  data() {
    return {
      oid: '',
      ccid: '',
      actId: '',
      fatherName: '',
      createtime: '',
      curriculumName: '',
      signlist: [],
      signData: [],
      formInline: {
        university: '',
        academy: '',
        name: '',
        PageNum: 1,
        PageSize: 10,
        path: 'wss://xiangqian.club:8001/ws/asset',
        socket: ''
      },
      actId: '',
      // 分页
      total: 0,
      fatherName: '', //课程名
      createtime: '', //创建时间
      //修改时长对话框
      editTimeDialog: false,
      //现在使用的环节
      row: null,
      edittimeform: {
        listtime: null
      },
      //表单验证信息
      rules: {
        listtime: [{ required: true, message: '请选择时长', trigger: 'change' }]
      },
      signStopType: null,
      options: [
        {
          value: 10,
          label: '+10'
        },
        {
          value: 5,
          label: '+5'
        },
        {
          value: -5,
          label: '-5'
        },
        {
          value: -10,
          label: '-10'
        }
      ]
    }
  },
  created() {
    this.tid = window.sessionStorage.getItem('tid')
    // this.ccid = window.sessionStorage.getItem('ccid')
    // this.oid = window.sessionStorage.getItem('soid')
    // this.curriculumName = window.sessionStorage.getItem('curriculumName')
    this.oid = this.$route.query.oid
    this.ccid = this.$route.query.ccid
    this.actId = this.$route.query.actId
    this.fatherName = this.$route.query.fatherName
    this.createtime = this.$route.query.createtime
    this.curriculumName = this.$route.query.curriculumName
    this.selectList()
  },
  filters: {
    formatStatus(value) {
      if (value === 0) {
        return '待激活'
      } else if (value === 1) {
        return '签到进行中'
      } else {
        return '签到已结束'
      }
    }
  },
  methods: {
    //激活签到
    async jihuo(data1) {
      console.log('列表数据', data1)
      this.$confirm('此操作将激活该签到, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          // console.log("别看了是这里")
          const { data: res } = await this.$http.get('/act/updateLinkStatus', {
            params: {
              duration: data1.duration,
              lid: data1.lid,
              status: 1
            }
          })

          console.log(res)

          if (res.code == 200) {
            this.selectList()
            this.$message({
              message: '激活成功',
              type: 'success'
            })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消激活'
          })
        })
    },
    //修改时长
    edittime(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post(
            '/act/updateLinkDuration',
            qs.stringify({
              lid: this.row.lid,
              duration: parseInt(this.row.duration) + this.edittimeform.listtime
            })
          )
          if (res.code == 200) {
            this.selectList()
            this.$message({
              message: '修改成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }
          this.edittimeform.listtime = ''
          this.editTimeDialog = false
          this.$refs[formName].resetFields()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    //取消修改时长
    cancleedittime(formName) {
      this.edittimeform.listtime = ''
      this.editTimeDialog = false
      this.$refs[formName].resetFields()
    },
    // 实现分页
    // 更改了每页大小
    handleSizeChange(val) {
      this.formInline.PageNum = 1
      this.formInline.PageSize = val

      // 请求对应的分页数据
      this.selectList()
    },
    // 更改了第几页
    handleCurrentChange(val) {
      this.formInline.PageNum = val
      this.selectList()
    },

    // 重置搜索表单
    handleResetSearch() {
      this.formInline = Object.assign({}, defaultFormInline)
    },
    //打开修改时长对话框
    openeditTimeDia(index, row) {
      // console.log(row)
      // console.log(parseInt(row.duration))
      this.row = row
      this.editTimeDialog = true
    },

    // 跳转到签到结果
    handleResultOrder(index, row) {
      this.$router.push({
        path: '/zuzhi/courseHistoryLink/courseHistoryResult',
        query: {
          oid: this.oid,
          ccid: this.ccid,
          actId: this.actId,
          fatherName: this.fatherName,
          curriculumName: this.curriculumName,
          createtime: this.createtime,
          lid: row.lid
        }
      })
    },
    // 查询签到环节
    async selectList() {
      const { data: res } = await this.$http.get('act/linkList', {
        params: {
          actid: this.actId,
          pageNum: this.formInline.PageNum,
          pageSize: this.formInline.PageSize
        }
      })
      if (res != null) {
        console.log(res)
        this.signData = res.Link
        this.total = res.total
        this.signStopType = res.signStopType
        console.log(this.signStopType)
        window.sessionStorage.setItem('signStopType', res.signStopType)
      }
    }
  }
}
</script>

<style scoped>
.app-container {
  width: 100%;
  /* height: 100vh;
  padding-bottom: 300px;
  overflow: auto !important; */
}

.input-width {
  width: 203px;
}

.operate-container .course-title {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.operate-container .course-title .title {
  font-size: 30px;
  font-weight: bold;
}

.operate-container .course-title .time {
  font-size: 16px;
}
</style>
