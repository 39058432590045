<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/home/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/home/signList/signList?tid=' + tid }"
        >签到活动</el-breadcrumb-item
      >
      <el-breadcrumb-item>签到环节</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="table-container">
      <i class="el-icon-tickets"></i>
      <span>签到环节列表</span>
      <el-table
        :data="signData"
        border
        stripe
        style="width: 100%"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column prop="linkType" label="签到环节" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.linkType }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="startTime"
          label="开始时间"
          sortable
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.startTime }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="endTime"
          label="结束时间"
          sortable
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.endTime }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="duration"
          label="签到时限"
          sortable
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.duration }}分钟</p>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="激活状态" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.status | formatStatus }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="address" width="300" label="操作" align="center">
          <template slot-scope="scope">
            <!-- <el-button
              type="primary"
              size="mini"
              @click="handleViewOrder(scope.$index, scope.row)"
              >签到进程</el-button
            > -->
            <el-button
              size="mini"
              type="danger"
              @click="handleResultOrder(scope.$index, scope.row)"
              >签到结果</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next,jumper"
        :current-page.sync="formInline.pageNum"
        :page-size="formInline.pageSize"
        :page-sizes="[5, 10, 15]"
        :total="total"
      ></el-pagination>
    </div>
    </el-card>

  </div>
</template>

<script>
const defaultFormInline = {
  pageNum: 1,
  pageSize: 10,
  university: null,
  academy: null,
  name: null,
}
export default {
  name: 'signlinkList',
  data() {
    return {
      signlist: [],
      signData: [],
      formInline: {
        university: '',
        academy: '',
        name: '',
        PageNum: 1,
        PageSize: 10,
      },
      actId: this.$route.query.actId,
      // 分页
      total: 0
    }
  },
  filters: {
    formatStatus(value) {
      if (value === 0) {
        return '待激活'
      } else if (value === 1) {
        return '签到进行中'
      } else {
        // return '已完成'
        return '已完成'
      }
    },
  },
  created() {
    this.selectList()
    this.actId = this.$route.query.actId
    this.tid = window.sessionStorage.getItem('tid')
    this.actId = window.sessionStorage.setItem('actId', this.actId)
    // console.log('actId', this.actId)
  },
  mounted: function () {
    // this.selectList()
  },
  methods: {
    // 实现分页
    // 更改了每页大小
    handleSizeChange(val) {
      this.formInline.PageNum = 1
      this.formInline.PageSize = val

      // 请求对应的分页数据
      this.selectList()
    },
    // 更改了第几页
    handleCurrentChange(val) {
      this.formInline.PageNum = val
      // console.log('更改页数', this.formInline.PageNum)
      this.selectList()
    },

    // 重置搜索表单
    handleResetSearch() {
      this.formInline = Object.assign({}, defaultFormInline)
    },
    // 查询签到环节
    // handleShow(index, row) {
    //   this.$router.push({ path: '/home/signlinkList', query: { actId: row.actid } })
    // },
    // 跳转到签到进程
    handleViewOrder(index, row) {
      // console.log('row', row)
      window.sessionStorage.setItem('duration', row.duration)
      window.sessionStorage.setItem('status', row.status)
      this.$router.push({
        path: '/home/signList/signlinkList/signDetail',
        query: { lid: row.lid },
      })
    },

    // 跳转到签到结果
    handleResultOrder(index, row) {
      this.$router.push({
        path: '/home/signList/signlinkList/signResult',
        query: { lid: row.lid },
      })
    },

    // formatter(row, column) {
    //   return row.address
    // },
    onSubmit() {
      // console.log('submit!')
    },
    // 查询签到环节
    async selectList() {
      const { data: res } = await this.$http.get('act/linkList', {
        params: {
          actid: this.actId,
          PageNum: this.formInline.PageNum,
          PageSize: this.formInline.PageSize,
        },
      })
      // console.log('listres1121', res)
      // console.log('this.PageNum', this.formInline.PageNum)
      // console.log('this.PageSize', this.formInline.PageSize)
      if (res != null) {
        this.signData = res.Link
        this.total = res.total
        // console.log('total12', this.signData)
      }
    },
  },
}
</script>

<style scoped>
.app-container {
  width: 100%;
  height: 100vh;
  padding-bottom: 300px;
  overflow: auto!important;
}
.input-width {
  width: 203px;
}
</style>
