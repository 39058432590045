<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb2">
      <el-breadcrumb-item
        :to="{ path: '/checkCommonApply2/checkCommonApply21' }"
        >审批申请</el-breadcrumb-item
      >
    </el-breadcrumb>
    <!-- 申请列表 -->
    <el-card class="righttable">
      <i class="el-icon-tickets"></i>
      <span>申请列表</span>
      <el-table
        :data="listData"
        border
        stripe
        style="width: 100%"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column
          prop="teacherName"
          label="申请人"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="groupName"
          label="申请组织"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="applyTime"
          label="申请时间"
          min-width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="status" sortable label="操作" align="center">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status == 1">已通过</el-tag>
            <el-tag type="danger" v-else-if="scope.row.status == 2"
              >未通过</el-tag
            >
            <div v-else>
              <el-button
                type="success"
                size="small"
                @click="handleAgree(scope.row)"
                >通过</el-button
              >
              <el-button
                type="warning"
                size="small"
                @click="openDialog(scope.row)"
                >驳回</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next,jumper"
          :current-page.sync="formInline.pageNum"
          :page-size="formInline.pageSize"
          :page-sizes="[5, 10, 15]"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
    <!-- 处理驳回申请对话框 -->
    <el-dialog title="驳回理由" :visible.sync="dialogVisible" width="35%">
      <el-form :model="reasonForm" style="margin-left:10%">
        <el-form-item>
          <el-input
            type="textarea"
            :rows="3"
            v-model="reasonForm.reason"
            placeholder="请输入驳回理由"
            style="width:85%"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleReject()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import qs from 'qs'
export default {
  data() {
    return {
      listData: [],
      // 分页
      formInline: {
        pageNum: 1,
        pageSize: 5
      },
      tid: null,
      total: 0,
      uid: '',
      dialogVisible: false,
      reasonForm: {
        reason: ''
      },
      //记录本条数据
      data: {}
    }
  },
  created() {
    this.uid = window.sessionStorage.getItem('uid')
    this.getList()
  },
  mounted() {},
  methods: {
    //获取申请记录表
    async getList() {
      const { data: res } = await this.$http.get(
        '/authority/getAuthApplyRecord',
        {
          params: {
            uid: '0',
            pageNum: this.formInline.pageNum,
            pageSize: this.formInline.pageSize
          }
        }
      )
      if (res != null) {
        this.listData = res.data.list
        this.total = res.data.total
      }
    },
    // 实现分页
    // 更改了每页大小
    handleSizeChange(val) {
      this.formInline.pageNum = 1
      this.formInline.pageSize = val
      this.getList()
    },
    // 更改了第几页
    handleCurrentChange(val) {
      this.formInline.pageNum = val
      this.getList()
    },
    //处理同意操作
    async handleAgree(data) {
      this.$confirm('此操作将同意此用户创建该学校, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await this.$http.post(
            '/authority/handleApply',
            qs.stringify({
              applyId: data.recordId,
              applyType: 1,
              handleType: 1,
              rejectReason: '无'
            })
          )
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '已同意!'
            })
            this.getList()
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    //打开驳回对话框
    openDialog(data) {
      this.data = data
      this.dialogVisible = true
    },
    //处理驳回操作
    async handleReject() {
      if (this.reasonForm.reason == null) this.reasonForm.reason = '无'
      const { data: res } = await this.$http.post(
        '/authority/handleApply',
        qs.stringify({
          applyId: this.data.recordId,
          applyType: 1,
          handleType: 0,
          rejectReason: this.reasonForm.reason
        })
      )
      if (res.code == 200) {
        this.$message({
          type: 'success',
          message: '已驳回!'
        })
        this.getList()
        this.dialogVisible = false
      } else {
        this.$message({
          type: 'info',
          message: '驳回失败'
        })
      }
    },
    handleClick() {},
    handleClose() {},
    handleChange() {}
  }
}
</script>

<style scoped></style>
