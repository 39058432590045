import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    user
  },
  getters,
  // state:{
  //   sharelid:''
  // },
  // mutations:{
  //   changesharelid(state,newlid){
  //     state.sharelid=newlid;
  //   }
  // }
})

export default store
